/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,  
:-ms-input-placeholder, 
::-ms-input-placeholder {     
    font-size: 14px;
    color: var(--celeste-600, #9295A6);
    font-weight: 500;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


.signinup-group {
    position: relative;
    margin: 0px 0 18px;
}

/* --- Signinup Lable Start --- */
.signinup-group .group__label {
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 1;
    font-size: 14px;
    color: #708494;
    font-weight: 500;
    margin: 0;
    -webkit-transform: scale(1) translateY(11px);
       -moz-transform: scale(1) translateY(11px);
        -ms-transform: scale(1) translateY(11px);
         -o-transform: scale(1) translateY(11px);
            transform: scale(1) translateY(11px);
    -webkit-transform-origin: left;
       -moz-transform-origin: left;
        -ms-transform-origin: left;
         -o-transform-origin: left;
            transform-origin: left;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding: 0 0px;
    pointer-events: none;
}

.signinup-group .group__label span{
    font-size: 14px;
    margin-left: 3px;
    color: #608ed7;
}
/* --- Signinup Lable End --- */

/* --- Signinup Lable End --- */
.signinup-group .group__line{
    position: absolute;
    top: 44px;
    left: 50%;
    width: 0px;
    height: 1px;
    -webkit-transform: translateX(-50%);
       -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
         -o-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: var(--white);
}
/* --- Signinup Lable End --- */


.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-weight: 500;
    display: block;
    color: var(--white);
    border: 0;
    font-size: 14px;
    border: 1px solid transparent;
    background-color: var(--darkblue);
    width: 100%;
    height: 44px;
    outline: none;
    padding: 10px 12px;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.gopageinput.signinup-group input{
    height: auto;
    padding: 4px 3px;
    width: 46px;
    text-align: center;
}

.signinup-group-style .signinup-group select,
.signinup-group-style .signinup-group textarea,
.signinup-group-style .signinup-group input{
    border: 1px solid var(--celeste-300);
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}


.signinup-group:not(.error-group) select:hover,
.signinup-group:not(.error-group) textarea:hover,
.signinup-group:not(.error-group) input:hover{
    border-color: var(--electricblue2-800);
}

.signinup-group textarea:not(:-moz-placeholder-shown), .signinup-group select:not(:-moz-placeholder-shown), .signinup-group input:not(:-moz-placeholder-shown),
.signinup-group textarea:not(:-ms-input-placeholder), .signinup-group select:not(:-ms-input-placeholder), .signinup-group input:not(:-ms-input-placeholder) {
    border-color: var(--electricblue2-800);
}

.signinup-group textarea:not(:placeholder-shown),
.signinup-group textarea:focus,
.signinup-group select:not(:placeholder-shown),
.signinup-group select:focus,
.signinup-group input:not(:placeholder-shown),
.signinup-group input:focus {
    border-color: var(--electricblue2-800);
}



.signinup-group.sgl-icon select,
.signinup-group.sgl-icon input{
    padding-left: 50px;
}

.signinup-group .group_left_icon{
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 44px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    font-size: 14px;
    color: var(--celeste-600);
    font-weight: 500;
}

.signinup-group .group_left_icon svg{
    height: 26px;
    opacity: .7;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 26px;
}




.signinup-group.sgr-icon select,
.signinup-group.sgr-icon input{
    padding-right: 44px;
}

.signinup-group .group_right_icon{
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 44px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
}

.signinup-group .group_right_icon svg{
    height: 26px;
    opacity: .7;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    width: 26px;
}

.signinup-group.sgri-select:hover .group_right_icon svg,
.signinup-group .group_right_icon.active svg{
    opacity: 1;
}

.signinup-group .group_right_icon.sgri-red:hover{
    color: var(--electricblue);
}


/* ---
Error Input color css
--- */

.error-group .signinup-group-checkmark.sgc-radio label .checkmark-icon,
.fileupload.error-group,
.error-group .selected-item,
.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: #f44336;
}

.error-group .selected-item .si-down-icon{
    color: #f44336;
}

/* .signinup-group.error-group .group__line{
    background-color: #f44336;
    width: 100%;
}

.signinup-group.error-group .group__label span{
    color: #f44336 !important;
} */

/* Form Error Msg */
.form-error-msg{
    display: block;
    text-align: right;
    color: #f44336;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: -5px;
    margin-top: 8px;
}

.sg-fem-style .form-error-msg{
    margin-top: -12px;
    margin-bottom: 15px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
    font-size: 10px;
}


/* --- --- */
.input-note{
    font-size: 12px;
    margin-top: 5px;
    color: #708494;
    /* font-weight: 500; */
}

.input-note-2{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
    margin-top: 10px;
}

.input-note-2 svg{
    width: 24px;
    height: 24px;
    position: relative;
    top: -1.5px;
}

/* --- Remember Input Checkbox --- */
.signinup-group-checkmark{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 30px;
    position: relative;
    margin-bottom: 18px;
}

.signinup-group-checkmark label{
    font-size: 12px;
    color: var(--white);
    font-weight: 400;
    margin: 0;
    display: block;
    cursor: pointer;
    line-height: 1.6;
}

.signinup-group-checkmark label a{
    color: #0294B5;
    font-weight: 500;
}

.signinup-group-checkmark label a:hover{
    text-decoration: underline;
}

.signinup-group-checkmark label .checkmark-icon{
    position: absolute;
    left: 0;
    top: 2px;
    width: 21px;
    height: 21px;
    display: inline-block;
    -webkit-border-radius: 4px;
       -moz-border-radius: 4px;
            border-radius: 4px;
    background-color: transparent;
    background-position: center;
    -webkit-box-shadow: 0 0 0px 4px transparent inset;
       -moz-box-shadow: 0 0 0px 4px transparent inset;
            box-shadow: 0 0 0px 4px transparent inset;
    border: 1px solid #708494;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--white);
}

.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark input:checked + label .checkmark-icon {
    background-color: #4CAF50;
    border-color: #4caf50;
    /* background-color: #0294B5;
    border-color: #0294B5; */
    background-image: url(../img/icons/check.svg);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: 80% 85%;
       -moz-background-size: 80% 85%;
         -o-background-size: 80% 85%;
            background-size: 80% 85%;
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;
}


/* --- signinup-group-checkmark is Radio btn --- */

.signinup-group-checkmark.sgc-radio{
    padding-left: 28px;
    margin-bottom: 13px;
    position: relative;
    z-index: 3;
}

.signinup-group-checkmark.sgc-check label,
.signinup-group-checkmark.sgc-radio label{
    font-size: 14px;
    color: var(--celeste-800);
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.signinup-group-checkmark.sgc-check label:hover,
.signinup-group-checkmark.sgc-radio label:hover{
    color: var(--celeste);
}

.signinup-group-checkmark.sgc-check label .checkmark-icon{
    border-color: #0294B5;
    top: 1px;
    width: 20px;
    height: 20px;
}

.signinup-group-checkmark.sgc-radio label .checkmark-icon{
    width: 22px;
    height: 22px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    top: 0px;
    border-color: #0294B5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    transition: all 0.3s ease-in-out;
}

.signinup-group-checkmark.sgc-radio label .checkmark-icon .cmi-inner{
    background-color: #0294B5;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.1s ease-in-out;
}

.signinup-group-checkmark.sgc-radio input:checked + label{
    color: var(--white);
}

.signinup-group-checkmark.sgc-check input:checked + label .checkmark-icon{
    background-color: #0294B5;
    border-color: #0294B5;
    /* background-size: 80% 80%; */
    background-size: 14px;  
}

.signinup-group-checkmark.sgc-radio input:checked + label .checkmark-icon{
    background-color: transparent;
    border-color: #0294B5;
    background-image: none;
}

.signinup-group-checkmark.sgc-radio input:checked + label .checkmark-icon .cmi-inner{
    transform: scale(1);
}


/* --- Input Autofill Css --- */
.signinup-group textarea:-webkit-autofill,
.signinup-group textarea:-webkit-autofill:hover,
.signinup-group textarea:-webkit-autofill:focus,
.signinup-group textarea:-webkit-autofill:active,
.signinup-group select:-webkit-autofill,
.signinup-group select:-webkit-autofill:hover,
.signinup-group select:-webkit-autofill:focus,
.signinup-group select:-webkit-autofill:active,
.signinup-group input:-webkit-autofill,
.signinup-group input:-webkit-autofill:hover,
.signinup-group input:-webkit-autofill:focus,
.signinup-group input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 14px !important;
    caret-color: white !important;
    color: white !important;
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: inset 0 0 0 100px var(--darkblue) !important;
            box-shadow: inset 0 0 0 100px var(--darkblue) !important;
}


/* --- login & submit btn --- */
.common-submit-btn,
.lsb-loader-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 12px 15px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    width: 100%;
    font-weight: 500;
    color: var(--white);
    font-size: 14px;
    margin: 0 auto 13px;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #0294B5;
    -webkit-transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-transform 0.3s ease;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-transform 0.3s ease;
    -o-transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, -o-transform 0.3s ease;
    -moz-transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease, -moz-transform 0.3s ease;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease;
    transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease, -webkit-transform 0.3s ease, -moz-transform 0.3s ease, -o-transform 0.3s ease;
    max-width: 450px;
    width: 100%;
    letter-spacing: 0.5px;
}

.common-submit-btn[disabled]{
    background-color: #7c868e;
}

.common-submit-btn:hover{
    color: var(--white);
}


/* --- lsb loader btn --- */
.lsb-loader-btn img{
    pointer-events: none;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    -webkit-animation: rotation-btn 1s infinite linear;
       -moz-animation: rotation-btn 1s infinite linear;
         -o-animation: rotation-btn 1s infinite linear;
            animation: rotation-btn 1s infinite linear;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}


/* --- signinup-group-profile Start ---  */


.signinup-group-profile{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.signinup-group-profile input{
    display: none;
}

.signinup-group-profile .sgp-lable{
    position: relative;
    width: 125px;
    height: 125px;
    background-color: var(--darkblue);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.signinup-group-profile .sgp-lable img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.signinup-group-profile .sgp-lable img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.signinup-group-profile .sgp-lable .sgp-overlay{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 500;
    letter-spacing: 0.5px;
    width: 100%;
    height: 100%;
    color: var(--white);
    background-color: var(--darkblue-300);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.signinup-group-profile .sgp-lable .sgp-overlay svg{
    width: 40px;
    height: 40px;
}

.signinup-group-profile .sgp-lable:hover .sgp-overlay{
    opacity: 1;
    visibility: visible;
}
/* --- signinup-group-profile End ---  */


/* --- signinup-group-switche Start ---  */
.signinup-group-switche{
    position: relative;
    background-color: var(--celeste-100);
    border-radius: 50px;
    height: 24px;
    width: 50px;
    flex: 0 0 50px;
    box-shadow: 0 0 0 1px var(--celeste-300);
    transition: all 0.3s linear;
    cursor: pointer;
}

.signinup-group-switche .sgs-inner{
    position: absolute;
    left: calc(0px + 3px);
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--celeste);
    transition: all 0.1s linear;
}

@media screen and (min-width:576px) {   
    .signinup-group-switche:hover{
        box-shadow: 0 0 0 1px var(--electricblue);
    }
    
    .signinup-group-switche:hover .sgs-inner{
        background-color: var(--electricblue);
    }
}

.signinup-group-switche.active{
    box-shadow: 0 0 0 1px var(--success);
}

.signinup-group-switche.active .sgs-inner{
    background-color: var(--success);
    left: calc(100% - 2px - 20px);
}

/* --- signinup-group-switche End ---  */


/* --- otp-group --- */
.signinup-group.otp-group{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0px 0 5px;
}

.signinup-group.otp-group input{
    width: 48px;
    height: 48px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 48px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 48px;
            flex: 0 0 48px;
    margin: 0 5px;
    text-align: center;
    padding: 0;
    font-size: 18px;
}

.otp-resend-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 18px;
    margin-bottom: 18px;
}

.otp-resend-bx div{
    color: var(--white-800);
    font-size: 12px;
    font-weight: 500;
}

.otp-resend-bx div span{
    color: var(--white);
    font-weight: 500;
}

.otp-resend-bx .reotp-btn{
    color: #0294B5;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    -webkit-border-radius: 3px;
       -moz-border-radius: 3px;
            border-radius: 3px;
}

.otp-resend-bx .reotp-btn:hover{
    text-decoration: underline;
    /* background-color: var(--white-100); */
}


/* --- --- */
.sg-country-code input{
    padding-left: 58px;
}

.sg-country-code .PhoneInputCountry{
    position: absolute;
    top: 0;
    left: 10px;
    height: 48px;
}

.sg-country-code .PhoneInputCountrySelectArrow{
    padding: 3px;
    border-width: 0 1.5px 1.5px 0;
    position: relative;
    top: -2px;
    left: 3px;
}

/* --- --- */
.sg-country-select .menu-flags-button{
    background-color: var(--darkblue);
    padding: 10px 15px;
    border: 1px solid transparent;
    color: #9295A6;
    font-family: 'Montserrat';
    font-weight: 500;
}

.sg-country-select .menu-flags-button.active{
    color: white;
    border-color:#0294B5;
}

.sg-country-select .menu-flags-button span{
    padding: 0;
}

.sg-country-select .menu-flags-button[aria-expanded="true"]::after,
.sg-country-select .menu-flags-button::after{
    content: " ";
    border: 2px solid #9295A6;
    border-top: none;
    border-left: none;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    transform-origin: center;
    position: relative;
    top: -4px;
    transition: transform 0.3s ease-in-out;
    margin: 0;
}

.sg-country-select .menu-flags-button[aria-expanded="true"]::after{
    border-color: #0294B5;
}

.sg-country-select .menu-flags-button .ReactFlagsSelect-module_selectFlag__2q5gC{
    font-size: 21px;
    margin-right: 10px;
}

.sg-country-select .menu-flags ul{
    background-color: var(--darkblue-900);
    border-color: var(--darkblue);
    backdrop-filter: blur(10px);
    max-height: 200px;
    border-radius: 10px;
}

.sg-country-select .menu-flags ul > li:hover{
    background-color: #0294B5;
    color:white
}

.sg-country-select .menu-flags ul > div{
    background-color: #090a0bbf;
    backdrop-filter: blur(10px);
    padding-bottom: 8px;
}


/* --- Date Picker Start --- */
.signinup-date-group{
    width: 100%;
    background-color: var(--darkblue);
}

.signinup-date-group .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    top: 0;
}

.signinup-date-group .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline,
.signinup-date-group .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
.signinup-date-group .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--celeste-300);
}

.signinup-date-group .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--electricblue2-800);
}

.signinup-date-group.error-group .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: #f44336;
}

.signinup-date-group .MuiInputBase-root .MuiOutlinedInput-notchedOutline legend{
    display: none;
}

.signinup-date-group .MuiFormLabel-root{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
    font-family: 'Montserrat';
    transform: none;
    transition: none;
    top: 3px;
    left: 3px;
    z-index: 1;
    background: var(--darkblue);
    width: calc(100% - 46px);
    height: calc(100% - 4px);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.signinup-date-group .MuiFormLabel-root.Mui-error{
    color: var(--celeste-600);
}

.signinup-date-group.Mui-selected .MuiFormLabel-root,
.signinup-date-group .MuiFormLabel-root.Mui-focused{
    opacity: 0;
    visibility: hidden;
}

.signinup-date-group .MuiInputBase-root .MuiInputBase-input{
    height: 46px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
    font-family: 'Montserrat';
    background-color: var(--darkblue);
}

.signinup-date-group .MuiInputBase-root .MuiSvgIcon-root{
    color: var(--celeste-300);
    transition: all 0.3s ease-in-out;
}

/* .signinup-date-group .MuiInputBase-root.Mui-focused .MuiSvgIcon-root, */
.signinup-date-group .MuiInputBase-root .MuiSvgIcon-root:hover{
    color: var(--electricblue);
}

.MuiPaper-root{
    border: 1px solid var(--celeste-200);
    background-color: var(--darkblue) !important;
}

.MuiPaper-root .MuiPickersCalendarHeader-label,
.MuiPaper-root .MuiTypography-root{
    font-family: 'Montserrat';
    color: var(--celeste) !important;
}

.MuiPaper-root .MuiPickersYear-yearButton,
.MuiPaper-root .MuiPickersMonth-monthButton,
.MuiPaper-root .MuiButtonBase-root{
    font-family: 'Montserrat';
    color: var(--celeste-800);
}

.MuiPaper-root .MuiPickersYear-yearButton.Mui-disabled:not(.Mui-selected),
.MuiPaper-root .MuiPickersMonth-monthButton.Mui-disabled:not(.Mui-selected),
.MuiPaper-root .MuiButtonBase-root.Mui-disabled:not(.Mui-selected){
    font-family: 'Montserrat';
    color: var(--celeste-800);
}


.MuiPaper-root .MuiPickersYear-yearButton:hover:not(.Mui-selected),
.MuiPaper-root .MuiPickersMonth-monthButton:hover:not(.Mui-selected),
.MuiPaper-root .MuiButtonBase-root:hover:not(.MuiPickersDay-today, .Mui-selected) {
    color: var(--celeste);
    background-color: var(--celeste-100) !important;
}

.MuiPaper-root .MuiPickersDay-today {
    color: var(--electricblue) !important;
    border-color: var(--electricblue) !important;
    background-color: transparent !important;
}

.MuiPaper-root .MuiPickersYear-yearButton.Mui-selected,
.MuiPaper-root .MuiPickersMonth-monthButton.Mui-selected,
.MuiPaper-root .MuiButtonBase-root.Mui-selected{
    background-color: var(--electricblue-500) !important;
}
/* --- Date Picker End --- */



/* --- Common File Upload Css Start --- */
.fileupload{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 10px;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    background-color: var(--black-200);
    border: 1px dashed var(--celeste-300);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.fileupload .feud-img{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload svg{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable{
    color: var(--celeste);
    font-weight: 500;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-sub-lable{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.fileupload .feud-lable span{
    color: var(--electricblue);
    text-decoration: underline;
}

.fileupload.dragover,
.fileupload:hover{
    border-style: solid;
    border-color: var(--electricblue);
}

.fileupload.dragover *{
    pointer-events: none
}

.fileupload.dragover .feud-img,
.fileupload.dragover svg,
.fileupload:hover svg,
.fileupload:hover .feud-img{
    opacity: 1;
}

.fileupload:hover .feud-lable{
    color: white;
}
/* --- Common File Upload Css End --- */


/* --- Common File Upload Item Css Start --- */
.upload-document-item{
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--celeste-100);
}

.upload-document-item .udi-img-bx{
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background-color: var(--black-200);
    border-radius: 8px;
    overflow: hidden;
}

.upload-document-item .udi-img-bx img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.upload-document-item .udi-data-bx{
    flex: auto;
}

.upload-document-item .udi-data-bx .udi-data-name{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.upload-document-item .udi-data-bx .udi-data-type-size{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-top: 5px;
}

.upload-document-item .udi-icon-bx{
    cursor: pointer;
    position: relative;
    color: var(--celeste-800);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    padding: 10px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: var(--black-200);
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx:active,
.upload-document-item .udi-icon-bx:hover{
    color: var(--celeste);
}

.upload-document-item .udi-icon-bx:hover::after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.upload-document-item .udi-icon-bx svg{
    position: relative;
    z-index: 1;
}

.upload-document-item .udi-icon-bx.udi-delete:active,
.upload-document-item .udi-icon-bx.udi-delete:hover{
    color: var(--error);
}
/* --- Common File Upload Item Css End --- */


/* --- Extra Label Css Start --- */
.sgr-outer-label{
    color: var(--celeste-800);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
}
/* --- Extra Label Css End --- */


/* --- Password rules start --- */
.password-rules-bx{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 8px;
}

.password-rules-bx .prb-item{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    color: var(--celeste-600);
    transition: all 0.3s ease-in-out;
}

.password-rules-bx .prb-item svg{
    width: 10px;
    height: 10px;
    object-fit: contain;
    object-position: center;
}

.password-rules-bx .prb-item svg.dotSvg{
    padding: 2px;
}

/* --- Password rules End --- */