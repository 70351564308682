html[data-theme=light-theme]{
    --error: #eb2e2e;
    --success: #00d69a;
    --pending: #fd7e14;
}

html[data-theme=light-theme] a, html[data-theme=light-theme]a:hover{
    color: var(--black);
}

/* --- Common Color class --- */
html[data-theme=light-theme] .color-red,
html[data-theme=light-theme] .color-error{
    color: var(--error) !important;
}

html[data-theme=light-theme] .color-green,
html[data-theme=light-theme] .color-success{
    color: var(--success) !important;
}

html[data-theme=light-theme] .color-yellow,
html[data-theme=light-theme] .color-pending{
    color: var(--pending) !important;
}

html[data-theme=light-theme] .color-blue{
    color: var(--electricblue) !important;
}
/* --- Common Color class --- */

/* --- Body tag Css --- */
html[data-theme=light-theme] body{
    color: var(--darkblue);
    background-color: var(--light-s-gray2);
}

/* --- Web Loader Css start --- */
html[data-theme=light-theme] .web-preloader {
    background-color: var(--light-s-gray2);
}

html[data-theme=light-theme] .web-preloader span.web-inner {
    color: var(--black);
}
/* --- Web Loader Css end --- */


/* --- Skeletant Css Start --- */
html[data-theme=light-theme] .skeletant-design {
    background-color: var(--light-s-gray);
    background-image: linear-gradient(45deg, var(--light-s-gray3) 30%, var(--light-s-gray) 50%, var(--light-s-gray3) 70%);
    border: 1px solid var(--black-100);
}
/* --- Skeletant Css End --- */


/* --- Close Btn Css Start --- */
html[data-theme=light-theme] .close-icon{
    color: var(--darkblue);
}

@media screen and (min-width:700px) {
    html[data-theme=light-theme] .close-icon:hover{
        background-color: var(--darkblue-100);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    html[data-theme=light-theme] .close-icon:active{
        background: var(--darkblue-100);
    }
}
/* --- Close Btn Css End --- */

/* --- Common Back Btn Start --- */
html[data-theme=light-theme] .CommonBackBtn{
    color: var(--darkblue);
}

@media screen and (min-width:700px) {
    html[data-theme=light-theme] .CommonBackBtn:hover{
        background-color: var(--darkblue-200);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    html[data-theme=light-theme] .CommonBackBtn:active{
        background: var(--darkblue-200);
    }
}
/* --- Common Back Btn End --- */


/* --- Common Button Css Start --- */
html[data-theme=light-theme] .common-btn-item{
    color: var(--white);
    font-weight: 600;
}

html[data-theme=light-theme] .common-btn-item::after{
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--darkblue-300)));
    background-image: -webkit-linear-gradient(transparent, var(--darkblue-300));
    background-image: -moz-linear-gradient(transparent, var(--darkblue-300));
    background-image: -o-linear-gradient(transparent, var(--darkblue-300));
    background-image: linear-gradient(transparent, var(--darkblue-300));
}

html[data-theme=light-theme] .common-btn-item svg{
    stroke-width: 2px;
}

html[data-theme=light-theme] .common-btn-item.cbi-fill{
    border-color: var(--electricblue);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue)), to(var(--azure)) );
    background: -webkit-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -moz-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -o-linear-gradient( left, var(--electricblue), var(--azure) );
    background: linear-gradient( 90deg, var(--electricblue), var(--azure) );
}

html[data-theme=light-theme] .common-btn-item.cbi-whatsapp{
    border-color: #25D366;
    background: linear-gradient( 90deg, #25d465,  #25d465 );
}


html[data-theme=light-theme] .common-btn-item.cbi-outline{
    color: var(--darkblue);
    border-color: var(--darkblue);
}

html[data-theme=light-theme] .common-btn-item.cbi-outline::before{
    background-color: var(--darkblue);
}

html[data-theme=light-theme] .common-btn-item.cbi-remove{
    color: var(--darkblue-700);
    border-color: var(--darkblue-700);
}

html[data-theme=light-theme] .common-btn-item.cbi-none{
    color: var(--electricblue2);
}


@media only screen and (min-width: 768px) {

    html[data-theme=light-theme] .common-btn-item.cbi-outline:hover span,
    html[data-theme=light-theme] .cbi-white.common-btn-item.cbi-outline:hover{
        color: var(--white);
    }

    html[data-theme=light-theme] .common-btn-item.cbi-remove:hover{
        color: var(--error);
        border-color: var(--error);
    }
    
}
/* --- Common Button Css End --- */


/* --- Common Tabs Bx Start --- */
html[data-theme=light-theme] .common-tabs-bx::after{
    background-color: var(--darkblue-100);
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item::after{
    background-color:  var(--darkblue-100);
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item:hover,
html[data-theme=light-theme] .common-tabs-bx .ctb-item.active{
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item.active::after{
    background: linear-gradient(90deg, var(--electricblue), var(--azure));
}


/*  */
html[data-theme=light-theme] .common-tabs-bx.ctb-style-2 .ctb-item {
    transition: none;
}

html[data-theme=light-theme] .common-tabs-bx.ctb-style-2 .ctb-item.active {
    color: var(--white);
    border-color: transparent;
}

html[data-theme=light-theme] .common-tabs-bx.ctb-style-2 .ctb-item.active::after {
    background: linear-gradient(90deg, var(--electricblue), var(--azure));
}
/* --- Common Tabs Bx End --- */


/* --- Boot Modal Start --- */
html[data-theme=light-theme] .custom-content .modal-content,
html[data-theme=light-theme] .modal-content.custom-content{
    background-color: var(--white);
    box-shadow: none;
}

html[data-theme=light-theme] .custom-modal-header .cmh-icon svg{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .custom-modal-header .cmh-lable{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .custom-modal-header .cmh-sub-lable{
    color: var(--darkblue-700);
    font-weight: 500;
}

html[data-theme=light-theme] .custom-modal-header .cmh-sub-lable span{
    color: var(--darkblue);
}
/* --- Boot Modal End --- */


/* --- Common modal Information Modal Start --- */
html[data-theme=light-theme] .cm-info-item{
    background-color: var(--light-s-gray);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .cm-info-item .cmi-inner .cmi-i-info {
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .cm-info-item .cmi-inner .cmi-i-data {
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .cm-info-item .cmi-inner.cmi-copy .cmi-i-data .cmi-copy-icon {
    color: var(--darkblue);
}

html[data-theme=light-theme] .cm-info-item .cmi-inner.cmi-copy .cmi-i-data .cmi-copy-icon:hover {
    color: var(--darkblue);
    background-color: var(--darkblue-100);
}
/* --- Common modal Information Modal End --- */


/* --- Lightgallery start --- */
html[data-theme=light-theme] .lg-outer .lg-thumb-outer,
html[data-theme=light-theme] .lg-backdrop{
    background-color: #00000073 !important;
}
/* --- Lightgallery end --- */


/* --- Breadcrumb Css Start --- */
html[data-theme=light-theme] .breadcrumb-main-bx{
    background: var(--light-s-gray);
    box-shadow: 0 0 10px var(--darkblue-200);
}

html[data-theme=light-theme] .breadcrumb-back-icon{
    color: var(--darkblue-700);
    background-color: var(--darkblue-100);
}
html[data-theme=light-theme] .breadcrumb-back-icon svg{
    stroke-width: 2px;
}

html[data-theme=light-theme] .breadcrumb-back-icon:hover{
    color: var(--darkblue);
}

html[data-theme=light-theme] .breadcrumb-heading-bx{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 600;
    color: var(--darkblue-600);
}

html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link:hover{
    color: var(--darkblue);
}
/* --- Breadcrumb Css End --- */


/* --- Pagination Btn Css Start --- */
html[data-theme=light-theme] .pagination-bx .pagination-btn{
    color: var(--darkblue-800);
    font-weight: 600;
}

html[data-theme=light-theme] .pagination-bx .pagination-btn.disabled{
    color: var(--darkblue-600);
}

html[data-theme=light-theme] .pagination-bx .pagination-btn:hover{
    color: var(--electricblue2);
    background-color: var(--electricblue2-100);
}

html[data-theme=light-theme] .pagination-bx .pagination-text{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .pagination-bx .pagination-text div:nth-child(1){
    color: var(--electricblue2);
}

html[data-theme=light-theme] .pagination-bx .pagination-text span{
    color: var(--darkblue-600);
}
/* --- Pagination Btn Css End --- */

/* --- Empty Box Css start --- */
html[data-theme=light-theme] .empty-div-bx .empty-img {
    -webkit-filter: invert(0);
            filter: invert(0);
}

html[data-theme=light-theme] .empty-div-bx .empty-heading {
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .empty-div-bx .empty-sub-heading {
    font-weight: 600;
    color: var(--darkblue-700);
}
/* --- Empty Box Css End--- */


/* --- Notice Start--- */
html[data-theme=light-theme] .legal-content .legal-heading {
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .legal-content ul li,
html[data-theme=light-theme] .legal-content p {
    color: var(--darkblue-700);
    font-weight: 600;
}


html[data-theme=light-theme] .legal-content ul li a,
html[data-theme=light-theme] .legal-content p a {
    color: var(--electricblue2);
}

html[data-theme=light-theme] .legal-content ul strong,
html[data-theme=light-theme] .legal-content p strong {
    font-weight: 700;
    color: black;
}
/* --- Notice End --- */


/* --- Common scroll bar css start --- */
html[data-theme=light-theme] .common-srollbar::-webkit-scrollbar-track,
html[data-theme=light-theme] .filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar-track,
html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-track {
    background-color: var(--white);
}

html[data-theme=light-theme] .common-srollbar::-webkit-scrollbar-thumb,
html[data-theme=light-theme] .filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar-thumb,
html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb {
    background-color: var(--darkblue-200);
}

html[data-theme=light-theme] .common-srollbar::-webkit-scrollbar-thumb:hover,
html[data-theme=light-theme] .filter-drop-item .filter-drop-bx .filter-drop-inner::-webkit-scrollbar-thumb:hover,
html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--darkblue-300);
}
/* --- Common scroll bar css end --- */


/* --- Common Drop Bx Css Start --- */
html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0 0 10px 0px var(--black-100);
}
/* --- Common Drop Bx Css End --- */


/* --- --- */
html[data-theme=light-theme] .selected-item {
    background-color: var(--light-s-gray);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .common-drop-item:not(.error-group) .selected-item:hover,
html[data-theme=light-theme] .active > .selected-item{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .selected-item.si-none {
    background-color: var(--black-200);
}

html[data-theme=light-theme] .selected-item .si-img{
    filter: grayscale(1) invert(1) opacity(0.7);
}

html[data-theme=light-theme] .selected-item .si-data-bx .si-d-heading{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .selected-item  .si-down-icon{
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .common-drop-item:not(.error-group) .selected-item:hover .si-down-icon{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .active > .selected-item  .si-down-icon{
    color: var(--electricblue2);
}
/* --- --- */


/* --- --- */
html[data-theme=light-theme] .selecte-option-label{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .selecte-option-item{
    font-weight: 500;
}

html[data-theme=light-theme] .selecte-option-item::after {
    border-color: var(--electricblue);
    background: linear-gradient(90deg, var(--electricblue), var(--azure));
}

html[data-theme=light-theme] .selecte-option-item:not(.selected):hover {
    background-color: var(--light-s-gray2);
}

html[data-theme=light-theme] .selecte-option-item .soi-img{
    filter: grayscale(1) invert(1) opacity(0.7);
}

html[data-theme=light-theme] .selecte-option-item .soi-data-bx .soi-d-heading{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .selecte-option-item.selected .soi-img{
    filter: grayscale(1) invert(1) opacity(1);
}

html[data-theme=light-theme] .selecte-option-item.selected .soi-data-bx .soi-d-heading{
    color: var(--white);
}

html[data-theme=light-theme] .selecte-option-item .soi-data-bx .soi-d-heading .soi-dh-tag{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .selecte-option-item .soi-data-bx .soi-d-other{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .selecte-option-item .soi-data-bx .soi-d-balances{
    color: var(--black);
    font-weight: 600;
}

html[data-theme=light-theme] .selecte-option-item  .soi-down-icon{
    color: var(--darkblue-700);
}


/* --- common drop search input css end --- */
html[data-theme=light-theme] .common-drop-item .common-drop-bx .signinup-group{
    background-color: var(--white);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .signinup-group input{
    border-bottom: 1px solid var(--darkblue-100);
}
/* --- common drop search input css end --- */


html[data-theme=light-theme] .selected-item .si-lable{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .selected-item .si-data-bx .si-d-heading .si-dh-tag{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .selected-item .si-data-bx .si-d-other{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .selected-item .si-data-bx .si-d-balances{
    color: var(--black);
    font-weight: 500;
}
/* --- --- */


/* --- Custome Table Css Start --- */
html[data-theme=light-theme] .custom-table thead{
    background-color: var(--white);
}

html[data-theme=light-theme] .custom-table thead tr th{
    font-weight: 700;
    color: var(--darkblue);
}

html[data-theme=light-theme] .custom-table thead tr th .th-sort-icons{
    color: var(--darkblue-300);
}

html[data-theme=light-theme] .custom-table thead tr th:hover .th-sort-icons,
html[data-theme=light-theme] .custom-table thead tr th.active .th-sort-icons{
    color: var(--darkblue-600);
}

html[data-theme=light-theme] .custom-table thead tr th .th-sort-icons svg{
    width: 11px;
    height: 11px;
}

html[data-theme=light-theme] .custom-table thead tr th.asc .th-sort-icons svg:nth-child(1),
html[data-theme=light-theme] .custom-table thead tr th.desc .th-sort-icons svg:nth-child(2){
    color: var(--electricblue2);
}

    
html[data-theme=light-theme] .custom-table tbody tr td{
    font-weight: 600;
    color: var(--darkblue);
    background-color: var(--light-s-gray3);
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: var(--darkblue-100);
}

html[data-theme=light-theme] .custom-table tbody tr td:last-of-type{
    border-right-width: 1px;
}

html[data-theme=light-theme] .custom-table tbody tr td:first-child{
    border-left-width: 1px;
}

    
html[data-theme=light-theme] .custom-table tbody tr td div:not(.td-device-name, .td-status, .td-active-icon, .td-view, .symbol-bx),
html[data-theme=light-theme] .custom-table tbody tr td div.country-td{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .custom-table tbody tr td a{
    background-color: red;
}
    
html[data-theme=light-theme] .custom-table tbody tr td a:hover{
    background-color: var(--electricblue2-100);
    color: var(--electricblue2);
}
    
html[data-theme=light-theme] .custom-table tbody tr td .td-view:hover{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .custom-table tbody tr td .td-status.td-remove{
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .custom-table tbody tr td .td-status.td-remove:hover{
    color: var(--error);
}

html[data-theme=light-theme] .custom-table tbody tr td .td-status.td-pending{
    color: var(--pending);
}

html[data-theme=light-theme] .custom-table tbody tr td.td-red,
html[data-theme=light-theme] .custom-table tbody tr td .td-status.td-rejected{
    color: var(--error);
}

html[data-theme=light-theme] .custom-table tbody tr td.td-green,
html[data-theme=light-theme] .custom-table tbody tr td .td-status.td-approved{
    color: var(--success);
}

html[data-theme=light-theme] .custom-table tbody tr td.td-blue{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .custom-table.ct-hover tbody tr:hover td{
    background-color: var(--black-100);
}

html[data-theme=light-theme] .custom-table.ct-last-td-sticky tbody tr td:last-of-type{
    background-color: #0a121a;
}

html[data-theme=light-theme] .custom-table.ct-last-td-sticky.ct-hover tbody tr:hover td:last-of-type{
    background-color: #212c37;
}

html[data-theme=light-theme] .custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius{
    background-color: var(--darkblue);
}

html[data-theme=light-theme] .custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius::after{
    background-color: #0a121a;
}

html[data-theme=light-theme] .custom-table.ct-last-td-sticky.ct-hover tbody tr:hover td .td-last-radius::after{
    background-color: #212c37;
}
/* --- Custome Table Css End --- */

/* --- Common Progress Bar Start --- */
html[data-theme=light-theme] .common-progress-bx .common-progress-bar{
    background-color: var(--black-100);
}

html[data-theme=light-theme] .common-progress-bx .common-progress-bar .common-progress-inner{
    background: var(--electricblue);
    background-image: linear-gradient(90deg, var(--electricblue), var(--azure) );
}
/* --- Common Progress Bar End --- */


/* -- Common accordion Css Start --- */
html[data-theme=light-theme] .common-accordion .acc-item{
    border-bottom: 1px solid var(--darkblue-200);
}

html[data-theme=light-theme] .common-accordion:not(.vs-accordion) .acc-item:last-child {
    border-bottom: none;
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding span.down-icon {
    background-image: url("../img/icons/Minus-white.svg");
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding.collapsed span.down-icon {
    background-image: url("../img/icons/Plus-white.svg");
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding span.down-icon.di-two {
    background-image: url("../img/icons/Minus2.svg");
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding.collapsed span.down-icon.di-two {
    background-image: url("../img/icons/Plus2.svg");
}


html[data-theme=light-theme] .common-accordion .acc-item .acc-heding.collapsed span.down-icon.di-three,
html[data-theme=light-theme] .common-accordion .acc-item .acc-heding span.down-icon.di-three{
    background-image: none;
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding span.down-icon.di-three{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .common-accordion .acc-item .acc-heding.collapsed span.down-icon.di-three{
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-accordion .acc-item .faqs-data{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .common-accordion .acc-item .faqs-data strong{
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-accordion .acc-item .faqs-data a{
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-accordion  .acc-item .faqs-data a:hover{
    color: var(--electricblue2);
}
/* -- Common accordion Css End --- */



/* --- Chart Design Css Start--- */
html[data-theme=light-theme] .Mycharts .MuiChartsLegend-series text,
html[data-theme=light-theme] .Mycharts .MuiChartsAxis-label{
    font-weight: 600 !important;
    fill: var(--darkblue) !important;
}

html[data-theme=light-theme] .Mycharts .MuiChartsAxis-line,
html[data-theme=light-theme] .Mycharts .MuiChartsAxis-tick{
    stroke: var(--darkblue-100) !important;
}


html[data-theme=light-theme] .Mycharts .MuiChartsAxis-tickLabel{
    fill: var(--darkblue-700) !important;
    font-weight: 600 !important;
}

html[data-theme=light-theme] .Mycharts .MuiChartsGrid-line{
    stroke: #d2e5f108 !important;
}
/*--- Chart Design Css End ---*/


/*--- Platfrom Design Css Start ---*/
html[data-theme=light-theme] .platfrom-box{
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
    background: linear-gradient(180deg, var(--electricblue2-100), var(--cyan-300) 66.67%, var(--electricblue2-100)), var(--white);
}

html[data-theme=light-theme] .platfrom-box::before{
    background-color: var(--black-100);
}

html[data-theme=light-theme] .platfrom-box .pb-tag{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .platfrom-box .pb-heading{
    color: var(--darkblue);
}

html[data-theme=light-theme] .platfrom-box .pb-heading span{
    color: var(--electricblue2);
}


/* --- Playstores Buttons Start --- */

html[data-theme=light-theme] .playstores-btn .playstores-item{
    background-color: var(--white-600);
    border: 1px solid var(--darkblue-100);
    /* box-shadow: 0px 0px 15px -5px var(--black-100); */
    width: 181px;
}

html[data-theme=light-theme] .playstores-btn .playstores-item:hover{
    background-color: var(--white-800);
}

html[data-theme=light-theme] .playstores-btn .playstores-item .playstores-data .psd-lable{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .playstores-btn .playstores-item .playstores-data .psd-heading{
    color: var(--darkblue);
    font-weight: 600;
}
/* --- Playstores Buttons End --- */

/*--- Platfrom Design Css End ---*/



/* --- Commonform css start --- */
html[data-theme=light-theme] body::placeholder,
html[data-theme=light-theme] body::-webkit-input-placeholder,
html[data-theme=light-theme] body:-moz-placeholder,
html[data-theme=light-theme] body::-moz-placeholder,
html[data-theme=light-theme] body:-ms-input-placeholder,
html[data-theme=light-theme] body::-ms-input-placeholder {
    color: var(--darkblue-700);
    font-weight: 500;
}

html[data-theme=light-theme] .signinup-group select,
html[data-theme=light-theme] .signinup-group textarea,
html[data-theme=light-theme] .signinup-group input {
    font-weight: 500;
    color: var(--black);
    border: 1px solid var(--darkblue-100);
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .signinup-group:not(.error-group) select:hover,
html[data-theme=light-theme] .signinup-group:not(.error-group) textarea:hover,
html[data-theme=light-theme] .signinup-group:not(.error-group) input:hover{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group textarea:not(:-moz-placeholder-shown),
html[data-theme=light-theme] .signinup-group select:not(:-moz-placeholder-shown),
html[data-theme=light-theme] .signinup-group input:not(:-moz-placeholder-shown),
html[data-theme=light-theme] .signinup-group textarea:not(:-ms-input-placeholder),
html[data-theme=light-theme] .signinup-group select:not(:-ms-input-placeholder), 
html[data-theme=light-theme] .signinup-group input:not(:-ms-input-placeholder) {
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group textarea:not(:placeholder-shown),
html[data-theme=light-theme] .signinup-group textarea:focus,
html[data-theme=light-theme] .signinup-group select:not(:placeholder-shown),
html[data-theme=light-theme] .signinup-group select:focus,
html[data-theme=light-theme] .signinup-group input:not(:placeholder-shown),
html[data-theme=light-theme] .signinup-group input:focus {
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group .group_left_icon{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .signinup-group .group_right_icon.sgri-red:hover{
    color: var(--electricblue2);
}

/* --- Error Input color css --- */
html[data-theme=light-theme] .error-group .signinup-group-checkmark.sgc-radio label .checkmark-icon,
html[data-theme=light-theme] .fileupload.error-group,
html[data-theme=light-theme] .error-group .selected-item,
html[data-theme=light-theme] .signinup-group.error-group textarea,
html[data-theme=light-theme] .signinup-group.error-group select,
html[data-theme=light-theme] .signinup-group.error-group input{
    border-color: #f44336;
}

html[data-theme=light-theme] .error-group .selected-item .si-down-icon{
    color: #f44336;
}

/* Error Input Msg css */
html[data-theme=light-theme] .form-error-msg{
    font-weight: 600;
}

/* --- --- */
html[data-theme=light-theme] .input-note,
html[data-theme=light-theme] .input-note-2{
    font-weight: 600;
    color: var(--darkblue-700);
}


/* --- Remember Input Checkbox --- */
html[data-theme=light-theme] .signinup-group-checkmark label{
    color: var(--black);
    font-weight: 500;
}

html[data-theme=light-theme] .signinup-group-checkmark label a{
    color: var(--electricblue2);
    font-weight: 600;
}

html[data-theme=light-theme] .signinup-group-checkmark label .checkmark-icon{
    border: 1px solid var(--darkblue-100);
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group-checkmark input:checked + label .checkmark-icon {
    background-color: #4CAF50;
    border-color: #4caf50;
}


/* --- signinup-group-checkmark is Radio btn --- */
html[data-theme=light-theme] .signinup-group-checkmark.sgc-check label,
html[data-theme=light-theme] .signinup-group-checkmark.sgc-radio label{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-check label:hover,
html[data-theme=light-theme] .signinup-group-checkmark.sgc-radio label:hover{
    color: var(--darkblue);
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-check label .checkmark-icon,
html[data-theme=light-theme].signinup-group-checkmark.sgc-radio label .checkmark-icon{
    border-color: var(--darkblue-100);
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-check label:hover .checkmark-icon,
html[data-theme=light-theme].signinup-group-checkmark.sgc-radio label:hover .checkmark-icon{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-radio label .checkmark-icon .cmi-inner{
    background-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-radio input:checked + label{
    color: var(--black);
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-check input:checked + label .checkmark-icon{
    background-color: var(--electricblue2);
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .signinup-group-checkmark.sgc-radio input:checked + label .checkmark-icon{
    border-color: var(--electricblue2);
    background-color: var(--white);
}


/* --- Input Autofill Css --- */
html[data-theme=light-theme] .signinup-group textarea:-webkit-autofill,
html[data-theme=light-theme] .signinup-group textarea:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group textarea:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group textarea:-webkit-autofill:active,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:active,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:active {
    font-weight: 600 !important;
    font-size: 14px !important;
    caret-color: var(--black) !important;
    color: var(--black) !important;
    -webkit-text-fill-color: var(--black) !important;
    -webkit-box-shadow: inset 0 0 0 100px var(--light-s-gray) !important;
            box-shadow: inset 0 0 0 100px var(--light-s-gray) !important;
}


/* --- signinup-group-profile Start ---  */
html[data-theme=light-theme] .signinup-group-profile .sgp-lable{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
}


/* --- signinup-group-switche Start ---  */
html[data-theme=light-theme] .signinup-group-switche{
    background-color: var(--light-s-gray2);
    box-shadow: 0 0 0 1px var(--darkblue-300);
}

html[data-theme=light-theme] .signinup-group-switche .sgs-inner{
    background-color: var(--darkblue-300);
}

@media screen and (min-width:576px) {   
    html[data-theme=light-theme] .signinup-group-switche:hover{
        box-shadow: 0 0 0 1px var(--electricblue2);
    }
    
    html[data-theme=light-theme] .signinup-group-switche:hover .sgs-inner{
        background-color: var(--electricblue2);
    }
}

html[data-theme=light-theme] .signinup-group-switche.active{
    box-shadow: 0 0 0 1px var(--success);
}

html[data-theme=light-theme] .signinup-group-switche.active .sgs-inner{
    background-color: var(--success);
}
/* --- signinup-group-switche End ---  */


/* --- otp-group --- */
html[data-theme=light-theme] .otp-resend-bx div{
    color: var(--darkblue-800);
    font-weight: 600;
}

html[data-theme=light-theme] .otp-resend-bx div span{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .otp-resend-bx .reotp-btn{
    color: var(--electricblue2);
}



/* --- Date Picker Start --- */
html[data-theme=light-theme] .signinup-date-group{
    width: 100%;
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline,
html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline,
html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border: 1px solid var(--electricblue2);
}

html[data-theme=light-theme] .signinup-date-group.error-group .MuiInputBase-root .MuiOutlinedInput-notchedOutline{
    border-color: #f44336;
}

html[data-theme=light-theme] .signinup-date-group .MuiFormLabel-root{
    font-weight: 600;
    color: var(--darkblue-700);
    background: var(--light-s-gray);
}

html[data-theme=light-theme] .signinup-date-group .MuiFormLabel-root.Mui-error{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root .MuiInputBase-input{
    font-weight: 600;
    color: var(--darkblue-700);
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root .MuiSvgIcon-root{
    color: var(--darkblue-400);
    transition: all 0.3s ease-in-out;
}

/* html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root.Mui-focused .MuiSvgIcon-root, */
html[data-theme=light-theme] .signinup-date-group .MuiInputBase-root .MuiSvgIcon-root:hover{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .MuiPaper-root{
    border: 1px solid var(--darkblue-100);
    background-color: var(--white) !important;
}

html[data-theme=light-theme] .MuiPaper-root .MuiPickersCalendarHeader-label,
html[data-theme=light-theme] .MuiPaper-root .MuiTypography-root{
    color: var(--darkblue) !important;
    font-weight: 600;
}

html[data-theme=light-theme] .MuiPaper-root .MuiPickersYear-yearButton,
html[data-theme=light-theme] .MuiPaper-root .MuiPickersMonth-monthButton,
html[data-theme=light-theme] .MuiPaper-root .MuiButtonBase-root{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .MuiPaper-root .MuiPickersYear-yearButton.Mui-disabled:not(.Mui-selected),
html[data-theme=light-theme] .MuiPaper-root .MuiPickersMonth-monthButton.Mui-disabled:not(.Mui-selected),
html[data-theme=light-theme] .MuiPaper-root .MuiButtonBase-root.Mui-disabled:not(.Mui-selected){
    color: var(--darkblue-700);
}


html[data-theme=light-theme] .MuiPaper-root .MuiPickersYear-yearButton:hover:not(.Mui-selected),
html[data-theme=light-theme] .MuiPaper-root .MuiPickersMonth-monthButton:hover:not(.Mui-selected),
html[data-theme=light-theme] .MuiPaper-root .MuiButtonBase-root:hover:not(.MuiPickersDay-today, .Mui-selected) {
    color: var(--darkblue);
    background-color: var(--darkblue-100) !important;
}

html[data-theme=light-theme] .MuiPaper-root .MuiPickersDay-today {
    color: var(--electricblue2) !important;
    border-color: var(--electricblue2) !important;
}

html[data-theme=light-theme] .MuiPaper-root .MuiPickersYear-yearButton.Mui-selected,
html[data-theme=light-theme] .MuiPaper-root .MuiPickersMonth-monthButton.Mui-selected,
html[data-theme=light-theme] .MuiPaper-root .MuiButtonBase-root.Mui-selected{
    color: var(--white) !important;
    background-color: var(--electricblue2) !important;
}


/* --- Common File Upload Css Start --- */
html[data-theme=light-theme] .fileupload{
    background-color: var(--light-s-gray);
    border: 1px dashed var(--darkblue-100);
}

html[data-theme=light-theme] .fileupload .feud-lable{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .fileupload .feud-sub-lable{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .fileupload .feud-lable span{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .fileupload.dragover,
html[data-theme=light-theme] .fileupload:hover{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .fileupload:hover .feud-lable{
    color: black;
}


/* --- Common File Upload Item Css Start --- */
html[data-theme=light-theme] .upload-document-item{
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .upload-document-item .udi-img-bx{
    background-color: var(--black-100);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .upload-document-item .udi-data-bx .udi-data-name{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .upload-document-item .udi-data-bx .udi-data-type-size{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .upload-document-item .udi-icon-bx{
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .upload-document-item .udi-icon-bx::after{
    background-color: var(--black-100);
}

html[data-theme=light-theme] .upload-document-item .udi-icon-bx:active,
html[data-theme=light-theme] .upload-document-item .udi-icon-bx:hover{
    color: var(--darkblue);
}

html[data-theme=light-theme] .upload-document-item .udi-icon-bx.udi-delete:active,
html[data-theme=light-theme] .upload-document-item .udi-icon-bx.udi-delete:hover{
    color: var(--error);
}


/* --- Extra Label Css Start --- */
html[data-theme=light-theme] .sgr-outer-label{
    color: var(--darkblue);
    font-weight: 600;
}


/* --- Password rules start --- */
html[data-theme=light-theme] .password-rules-bx .prb-item{
    color: var(--darkblue-700);
    font-weight: 600;
}

/* --- Password rules End --- */

/* --- Commonform css end --- */


/* --- Signinup css start --- */

html[data-theme=light-theme] body.signinup-page{
    background-color: var(--darkblue);
}

html[data-theme=light-theme] .signinup-screen {
    background: var(--white);
}

/* ---
Signinup Link
--- */

.signinup-link-outer-bx{
    font-weight: 600;
}

.signinup-link{
    color: var(--white-800);
    display: block;
    font-size: 12px;
    font-weight: 500;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
}

.signinup-link:hover{
    color: var(--white);
    text-decoration: underline;
}

.signinup-link-two{
    cursor: pointer;
    color: #0294B5;
    display: block;
    font-size: 12px;
    font-weight: 600;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.signinup-link-two:hover{
    color: #0294B5;
    text-decoration: underline;
}

/* ---
Screen heading
--- */
html[data-theme=light-theme] .screen-hed {
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .screen-pera {
    color: var(--darkblue-700);
    font-weight: 600;
    font-size: 14px;
}

html[data-theme=light-theme] .screen-pera .sp-otp-link{
    color: var(--black);
}

html[data-theme=light-theme] .screen-pera .sp-email {
    color: var(--black);
}

html[data-theme=light-theme] .screen-pera .sp-otp-link:hover{
    color: var(--electricblue2);
}
/* --- Signinup css end --- */


/* --- Header Section Css Start --- */
html[data-theme=light-theme] .custom-header{
    background-color: var(--white);
    box-shadow: 0 0 10px var(--darkblue-200);
}

/* --- Aside Menu Icon Start --- */
html[data-theme=light-theme] .menu-icon-bx span {
    background-color: var(--darkblue);
}
/* --- Aside Menu Icon End --- */

/* --- --- */
html[data-theme=light-theme] .cr-icons-link{
    color: var(--darkblue);
}

html[data-theme=light-theme] .cr-drop-item:hover > .cr-icons-link,
html[data-theme=light-theme] .cr-icons-link:hover{
    color: var(--darkblue);
    background-color: var(--darkblue-100);
}

html[data-theme=light-theme] .cr-drop-item .ch-user-bx .cr-icons-link{
    background-color: var(--darkblue-100);
}
/* --- --- */

/* --- ch user bx Start --- */
html[data-theme=light-theme] .ch-user-bx .cub-text-bx{
    color: var(--darkblue-600);
}

html[data-theme=light-theme] .cr-drop-item:hover .cub-text-bx,
html[data-theme=light-theme] .ch-user-bx:hover .cub-text-bx{
    color: var(--darkblue);
}
/* --- ch user bx End --- */


/* --- Drop item Start --- */
html[data-theme=light-theme] .cr-drop-item .cr-drop-bx .cr-drop-inner{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}

html[data-theme=light-theme] .cr-drop-item .cr-drop-bx .cr-drop-inner::after{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .cdi-main .cdi-main-item{
    color: var(--darkblue-800);
    font-weight: 600;
}

html[data-theme=light-theme] .cdi-main .cdi-main-item:hover{
    color: var(--darkblue);
}

html[data-theme=light-theme] .cdi-main .cdi-main-item.active{
    color: var(--darkblue);
}


html[data-theme=light-theme] .cdi-user-data{
    border-bottom: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .cdi-user-data .cud-name{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .cdi-user-data .cud-other{
    color: var(--darkblue-800);
    font-weight: 500;
}
/* --- Drop item End --- */

/* --- Header Section Css End --- */


/* --- account side menu css start --- */
html[data-theme=light-theme] .account-side-menu{
    background-color: var(--white);
    box-shadow: 0 0 10px 0px var(--black-200);
}

/* --- Account Left logo start --- */
html[data-theme=light-theme] .account-side-menu .asm-logo{
    background-color: var(--white);
}

html[data-theme=light-theme] .account-side-menu .asm-logo::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    background-color: var(--darkblue-100);
    height: 1px;
    width: calc(100% - 20px);
}
/* --- Account Left logo end --- */


/* --- Account Left Side Menu Start --- */
html[data-theme=light-theme]  .amb-section-lable{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .account-menu-bx .amb-link{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .account-menu-bx .amb-link.collapsed span svg{
    color: currentColor;
}

html[data-theme=light-theme] .account-menu-bx .amb-link span svg{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .account-menu-bx .amb-link:not(.active):hover{
    background-color: var(--darkblue-100);
    color: var(--darkblue);
    text-shadow: none;
}

html[data-theme=light-theme] .account-menu-bx .amb-link.active{
    background-color: var(--electricblue2-100);
    color: var(--electricblue2);
}

html[data-theme=light-theme] .account-menu-bx .amb-link::before{
    box-shadow: none;
}

html[data-theme=light-theme] .account-menu-bx .amb-link::after{
    background-color: var(--electricblue2);
}

html[data-theme=light-theme] .account-menu-bx .amb-link .amb-num{
    font-weight: 500;
    font-size: 10px;
}

/* --- Amb Drop Bx Start --- */
html[data-theme=light-theme] .amb-drop-bx .amb-drop-item::after{
    background-color: var(--darkblue-100);
}
/* --- Amb Drop Bx End --- */

/* --- Account Left Side Menu End --- */

/* --- account side menu css end --- */


/* --- account right css start --- */
html[data-theme=light-theme] .account-content-area{
    background-color: var(--light-s-gray2);
}

/* --- Common box start --- */
html[data-theme=light-theme] .common-box{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}

html[data-theme=light-theme] .common-box.cb-p-bg-none{
    background-color: transparent;
    box-shadow: none;
    border: none;
}

html[data-theme=light-theme] .common-box-heading.cbh-big,
html[data-theme=light-theme] .common-box-heading{
    color: var(--black);
    font-weight: 600;
}

html[data-theme=light-theme] .common-box-sub-heading{
    font-weight: 500;
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-box-heading svg{
    color: var(--electricblue2);
    stroke-width: 2px;
}

html[data-theme=light-theme] .common-box-heading .cbh-count{
    color: var(--darkblue-400);
}

html[data-theme=light-theme] .common-box-heading .cbh-count span{
    color: var(--darkblue);
}

html[data-theme=light-theme] .common-box-info{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .common-box-info:hover{
    color: var(--electricblue2);
    background-color: var(--darkblue-100);
}

html[data-theme=light-theme] .common-box-link{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .common-box-link:hover{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .cbs-filter-top{
    background-color: var(--light-s-gray) !important;
    border: none;
}

html[data-theme=light-theme] .cbs-filter-top{
    border-bottom: 1px solid var(--darkblue-100);
}
/* --- Common box end --- */



/* --- Transaction Item Start --- */
html[data-theme=light-theme] .transaction-item{
    background-color: var(--light-s-gray);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .transaction-item .ti-inner .ti-i-info{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .transaction-item .ti-inner .ti-i-data{
    font-weight: 600;
    color: var(--darkblue);
}
/* --- Transaction Item End --- */



/* --- verification status bx Start --- */
html[data-theme=light-theme] .verification-status-bx .vsb-level-bx{
    color: var(--black);
}

html[data-theme=light-theme] .verification-status-bx .vsb-progress-bx .vsb-progress-bar{
    background-color: var(--black-200);
}

html[data-theme=light-theme] .verification-status-bx .vsb-progress-bx .vsb-progress-num{
    color: var(--darkblue);
    font-weight: 600;
}

/* --- Verified List css start --- */
html[data-theme=light-theme] .verification-list-bx .vlb-item{
    color: var(--darkblue-700);
    font-weight: 500;
}

html[data-theme=light-theme] .verification-list-bx .vlb-item.active{
    color: var(--darkblue);
}

html[data-theme=light-theme] .verification-list-bx .vlb-item.active  svg{
    color: var(--electricblue2);
}
/* --- Verified List css end --- */



html[data-theme=light-theme]  .verification-note .vn-heading{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme]  .verification-note .vn-text{
    font-weight: 600;
    color: var(--darkblue-700);
}
 
/* --- verification status bx end --- */


/* --- verification status bx 2 start --- */
html[data-theme=light-theme] .verification-status-bx2 .vsb2-data-bx .vdb-icons-bx > svg{
    color: var(--darkblue);
}

html[data-theme=light-theme] .verification-status-bx2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle{
    color: var(--darkblue-200);
}

html[data-theme=light-theme] .verification-status-bx2.verified-step1 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(1),
html[data-theme=light-theme] .verification-status-bx2.verified-step2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(2),
html[data-theme=light-theme] .verification-status-bx2.verified-step2 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(1),
html[data-theme=light-theme] .verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(3),
html[data-theme=light-theme] .verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(2),
html[data-theme=light-theme] .verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-icons-bx .vdb-icon-circle svg path:nth-child(1){
    color: var(--electricblue2);
}

html[data-theme=light-theme] .verification-status-bx2 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--error);
}

html[data-theme=light-theme] .verification-status-bx2.verified-step1 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--electricblue2-600);
}

html[data-theme=light-theme] .verification-status-bx2.verified-step2 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--electricblue2-800);
}

html[data-theme=light-theme] .verification-status-bx2.verified-step3 .vsb2-data-bx .vdb-inner .vsb2-current{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .verification-status-bx2 .vsb2-data-bx .vdb-inner .vsb2-steps{
    color: var(--darkblue-700);
    font-weight: 600;
}
/* --- verification status bx 2 end --- */


/* --- verification status Accordion css start --- */
html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .acc-heding .vs-step-check,
html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .acc-heding .vs-step-num{
    color: var(--darkblue);
    background-color: var(--darkblue-100);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item.current-step .acc-heding .vs-step-num{
    background-color: var(--electricblue2);
    color: var(--white);
}


html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .acc-heding .vs-step-check svg,
html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item:not(.verified-step, .unverified-step, .pending-step) .acc-heding .vs-step-check{
    display: none;
}


html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item.pending-step .acc-heding .vs-step-check svg:nth-child(3),
html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item.unverified-step .acc-heding .vs-step-check svg:nth-child(2),
html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item.verified-step .acc-heding .vs-step-check svg:nth-child(1){
    display: block;
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .acc-heding .vs-step-check{
    color: var(--white);
    background-color: var(--electricblue);
    background-image: linear-gradient(90deg, var(--electricblue), var(--azure));
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item.unverified-step .acc-heding .vs-step-check{
    color: var(--white);
    background: var(--error);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item.pending-step .acc-heding .vs-step-check{
    color: var(--black);
    background: var(--pending);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .vs-accordion-data{
    border-top: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-label{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .vs-accordion-data .vad-data-list .vdl-item:not(:first-child):before{
    background-color: var(--darkblue-400);
}

html[data-theme=light-theme] .vs-accordion.common-accordion .acc-item .vs-accordion-btns{
    border-top: 1px solid var(--darkblue-100);
}
/* --- verification status Accordion css end --- */



/* --- Verification item css start --- */
html[data-theme=light-theme] .verification-item{
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .verification-item svg:nth-of-type(2){
    color: var(--electricblue2);
}

html[data-theme=light-theme] .verification-item.vi-verified svg:nth-of-type(2){
    opacity: 1;
    visibility: visible;
}

html[data-theme=light-theme] .verification-item .vi-data .vi-lable{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .verification-item .vi-data .vi-data{
    font-weight: 600;
    color: var(--darkblue-700);
}
/* --- Verification item css end --- */



/* --- Specification bx Start --- */
html[data-theme=light-theme] .specification-heading-bx .sahb-lable{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .specification-item-bx{
    background-color: var(--light-s-gray3);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .specification-item-bx .saib-lable{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .specification-item-bx .saib-icon span{
    background-color: var(--light-s-gray);
}
/* --- specification bx end --- */


/* --- signinup-group-detail-item start --- */
html[data-theme=light-theme] .signinup-group-detail-item .sgdi-lable{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .signinup-group-detail-item .sgdi-data{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .signinup-group-detail-item.sgdi-bottom-line{
    border-bottom: 1px solid var(--darkblue-100);
}
/* --- signinup-group-detail-item end --- */



/* --- Change password Bx End --- */
html[data-theme=light-theme] .change-password-bx .cpb-inner-bx .cpb-i-lable{
    color: var(--black);
    font-weight: 600;
}

html[data-theme=light-theme] .change-password-bx .cpb-inner-bx .cpb-i-date{
    font-weight: 600;
    color: var(--darkblue-600);
}
/* --- Change password Bx End --- */



/* --- Authentication Item Start--- */
html[data-theme=light-theme] .authentication-item{
    border: 1px solid var(--darkblue-100);
    background-color: var(--light-s-gray3);
}

html[data-theme=light-theme] .authentication-item .ai-i-heading{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .authentication-item .ai-i-tag{
    font-weight: 600;
}

html[data-theme=light-theme] .authentication-item .ai-i-text{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .authentication-item .authentication-prossesing{
    color: var(--darkblue);
    background-color: var(--darkblue-100);
}
/* --- Authentication Item End--- */


/* --- Document reject reason bx Start --- */
html[data-theme=light-theme]  .document-reject-reason-bx {
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .document-reject-reason-bx .drrb-lable{
    font-weight: 600;
}

html[data-theme=light-theme] .document-reject-reason-bx .drrb-text{
    font-weight: 600;
    color: var(--darkblue);
}
/* --- Document reject reason bx End --- */


/* --- wallet balances bx Start --- */
html[data-theme=light-theme] .wallet-balances-bx .wbb-inner-bx .wbb-i-label{
    font-weight: 600;
    color: var(--darkbule-700);
}

html[data-theme=light-theme] .wallet-balances-bx .wbb-inner-bx .wbb-i-balances{
    color: var(--black);
    font-weight: 600;
}

html[data-theme=light-theme] .wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn,
html[data-theme=light-theme] .wallet-balances-bx .wbb-inner-bx .wbb-i-balances span{
    color: var(--darkbule-700);
}

@media screen and (min-width:576px) {   
    html[data-theme=light-theme] .wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn:hover{
        color: var(--darkbule);
    }
}

html[data-theme=light-theme] .wallet-balances-bx .wbb-inner-bx .wbb-i-hide-btn:active{
    color: var(--darkbule);
}
/* --- wallet balances bx End --- */


/* --- filter-bx Start --- */
@media screen and (max-width:991px) {
    html[data-theme=light-theme] .filter-bx.transactions-bottom-filter,
    html[data-theme=light-theme] .filter-bx.ib-bottom-filter{
        background-color: var(--white);
        border-top: 1px solid var(--darkblue-100);
    }
}
/* --- filter-bx End --- */

/* --- Filter checkbox group Start --- */
html[data-theme=light-theme] .filter-checkbox-group label {
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .filter-checkbox-group label span.check-icon {
    box-shadow: 0 0 0px 4px var(--light-s-gray) inset;
    background-color: var(--light-s-gray);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .filter-checkbox-group input:checked + label {
    color: var(--darkblue);
}

html[data-theme=light-theme] .filter-checkbox-group input:checked + label span.check-icon {
    background-color: var(--electricblue2);
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .filter-checkbox-group.check-square input:checked+label span.check-icon {
    border-color: var(--electricblue2);
    background-color: var(--electricblue2);
}

html[data-theme=light-theme] .filter-checkbox-group.fcg-flex-row label {
    color: var(--darkblue-700);
    background-color: var(--light-s-gray2);
}

html[data-theme=light-theme] .filter-checkbox-group.fcg-flex-row label::after {
    border-color: var(--electricblue);
    background: linear-gradient(90deg, var(--electricblue), var(--azure));
}

html[data-theme=light-theme] .filter-checkbox-group.fcg-flex-row label:hover{
    background-color: var(--light-s-gray2) !important;
}

html[data-theme=light-theme] .filter-checkbox-group.fcg-flex-row input:checked + label {
    color: var(--white);
    background-color: transparent !important;
}

html[data-theme=light-theme] .filter-sapretor{
    background-color: var(--darkblue-100);
}
/* --- Filter checkbox group End --- */


/* --- Filter Drop Item Start --- */

html[data-theme=light-theme] .filter-sort-bx {
    background-color: var(--light-s-gray);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .filter-drop-item:hover .filter-sort-bx,
html[data-theme=light-theme] .filter-drop-item.active .filter-sort-bx {
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .filter-sort-bx .filter-sort-label{
    font-weight: 600;
    color: var(--darkblue-700);
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .filter-sort-bx .filter-sort-label:hover{
    color: var(--darkblue);
}

html[data-theme=light-theme] .filter-sort-bx .filter-sort-label:hover svg{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .active > .filter-sort-bx .filter-sort-label svg{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .filter-sort-bx .filter-sort-btn{
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .filter-sort-bx .filter-sort-btn:hover{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .filter-sort-bx .filter-sort-btn::after{
    background-color: var(--darkblue-100);
}


html[data-theme=light-theme] .filter-drop-item .filter-drop-bx .filter-drop-inner{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0 0 10px 0px var(--black-100);
}

html[data-theme=light-theme] .filter-drop-item .filter-drop-bx .filter-drop-inner::after{
    background-color: var(--darkblue);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .filter-drop-lable label{
    background-color: transparent !important;
}

/* --- Filter Drop Item End --- */


/* --- Payment Method Card Start --- */
html[data-theme=light-theme] .payment-method-card{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}

html[data-theme=light-theme] .payment-method-card:hover{
    border-color: var(--electricblue2);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}

html[data-theme=light-theme] .payment-method-card .pmc-inner{
    border-bottom: 1px solid var(--darkblue-100);
    background-color: var(--light-s-gray2);
}

html[data-theme=light-theme] .payment-method-card .pmc-inner .pmc-heading-bx .pmc-label{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .payment-method-card .pmc-unavailable{
    color: var(--electricblue2);
    font-weight: 600;
}

html[data-theme=light-theme] .payment-method-card .pmc-info{
    background-color: var(--light-s-gray2);
}

html[data-theme=light-theme] .payment-method-card .pmc-info .pmc-i-item{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .seleted-payment-methods .spmc-label{
    font-weight: 600;
    color: var(--black);
}
/* --- Payment Method Card End --- */


/* --- Payment tab bx start --- */
html[data-theme=light-theme] .payment-tab-item{
    font-weight: 600;
    color: var(--darkblue-700);
    border: 1px solid transparent;
}

html[data-theme=light-theme] .payment-tab-item .payment-img {
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .payment-tab-item:hover{
    background-color: var(--electricblue2-100);
    color: var(--darkblue);
}

html[data-theme=light-theme] .payment-tab-item:hover .payment-img {
    border-color: var(--darkblue-400);
}

html[data-theme=light-theme] .payment-tab-item.active{
    color: var(--electricblue2);
    background-color: var(--electricblue2-100);
}

html[data-theme=light-theme] .payment-tab-item.active .payment-img{
    border-color:var(--electricblue2) 
}
/* --- Payment tab bx end --- */


/* --- Payment data bx start --- */
html[data-theme=light-theme] .payment-data-bx.pdb-style-3,
html[data-theme=light-theme] .payment-data-bx.pdb-style-2{
    background-color: var(--light-s-gray2);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-lable{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .minimum-pdb-item.pdb-item .pdb-i-data .pdb-currency{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    color: var(--darkblue);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    color: var(--electricblue2);
    background-color: var(--electricblue2-100);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data strong{
    color: var(--darkblue);
}
/* --- Payment data bx end --- */


/* --- Notifications Css Start --- */

html[data-theme=light-theme] .notification-item{
    border-bottom: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .notification-item .ni-icon{
    background-color: var(--black-100);
}

html[data-theme=light-theme] .notification-item.ni-new .ni-icon::after{
    background-color: var(--electricblue2)
}

html[data-theme=light-theme] .notification-item:hover .ni-icon{
    color: var(--electricblue2);
    background-color: var(--electricblue2-100);
}

html[data-theme=light-theme] .notification-item .ni-data .ni-label{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .notification-item:hover .ni-data .ni-label{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .notification-item .ni-data .ni-text{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .notification-item .ni-data .ni-date-time,
html[data-theme=light-theme] .notification-item .ni-data .ni-status{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .notification-item .ni-data .ni-status span{
    color: var(--darkblue);
}
/* --- Notifications Css End --- */



/* --- Conversion Rate Bx Start --- */
html[data-theme=light-theme] .conversion-rate-bx .current-rate{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .conversion-rate-bx .current-amount{
    color: var(--darkblue-700);
    font-weight: 600;
    background-color: var(--black-100);
}

html[data-theme=light-theme] .conversion-rate-bx .current-amount span{
    color: var(--darkblue);
}

html[data-theme=light-theme] .conversion-rate-bx.crb-style-2 .current-amount{
    border: 1px solid var(--darkblue-100);
    background-color: var(--light-s-gray);
}
/* --- Conversion Rate Bx End --- */

/* --- Mobile Filter Bx Css Start --- */
html[data-theme=light-theme] .mobile-filter-bx{
    background-color: var(--white);  
    box-shadow: none;
}

html[data-theme=light-theme] .mobile-filter-bx .mfb-header{
    background-color:  var(--white);
    border-bottom: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .mobile-filter-bx .mfb-header-heading{
    color: var(--darkblue);
    font-weight: 600;
}

html[data-theme=light-theme] .mobile-filter-bx .mfb-header-heading svg{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .mobile-filter-bx .mfb-footer{
    border-top: 1px solid var(--darkblue-100);
    background-color: var(--white);
}
/* --- Mobile Filter Bx Css End--- */

/* --- --- */
html[data-theme=light-theme] .mt5-account-card{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}
    
html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner{
    background-color: var(--light-s-gray);
}


html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item{
    color: var(--darkblue-700);
    border: 1px solid var(--darkblue-300);
    font-weight: 600;
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item.mt-real,
html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item.mt5-current{
    color: var(--electricblue2);
    border-color: var(--electricblue2);
    text-transform: capitalize;
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-tags-bx .mt5-tag-item.mt-demo{
    color: var(--electricblue2);
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-id{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-balances{
    color: var(--black);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-balances span{
    color: var(--darkblue-700);
    font-weight: 600;
}


html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-other-data .mt5-od-item{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-other-data .mt5-od-item span{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx{
    border-top: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item .mt5-bi-icon{
    background-color: var(--darkblue-100);
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item.mt5-trade .mt5-bi-icon{
    color: var(--white);
    background: linear-gradient(90deg, var(--electricblue-600), var(--azure-600));
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item .mt5-bi-text{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item:hover .mt5-bi-icon{
    background-color: var(--electricblue2-100);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item:hover .mt5-bi-icon,
html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item:hover .mt5-bi-text{
    color: var(--electricblue2);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-btns-bx .mt5-btn-item.mt5-trade:hover .mt5-bi-icon{
    color: var(--white);
    background: linear-gradient(90deg, var(--electricblue-800), var(--azure-800));
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-archived-bx{
    border-top: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-archived-bx .archived-info{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .mt5-account-card .mt5-ac-inner .mt5-drop-icon:hover{
    background-color: var(--darkblue-100);
    color: var(--darkblue);
}
/* --- --- */

/* --- create account modal --- */
html[data-theme=light-theme] .create-account-btns-bx{
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .create-account-btns-bx .cabb-item{
    font-weight: 600;
    color: var(--darkblue-700);
    transition: none;
}

html[data-theme=light-theme] .create-account-btns-bx .cabb-item::after{
    background: linear-gradient(90deg, var(--electricblue), var(--azure));
}

html[data-theme=light-theme] .create-account-btns-bx .cabb-item:hover{
    color: var(--darkblue);
    background-color: var(--darkblue-100);
}

html[data-theme=light-theme] .create-account-btns-bx .cabb-item.active{
    color: var(--white);
}
/* --- create account modal --- */


/* --- Account Information Modal Start --- */
html[data-theme=light-theme] .ac-info-item{
    background-color: var(--light-s-gray);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .ac-info-item .ai-inner .ai-i-info {
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .ac-info-item .ai-inner .ai-i-data {
    color: var(--darkblue);
    font-weight: 600;
}
/* --- Account Information Modal End --- */


/* --- Trade modal item --- */
html[data-theme=light-theme] .trading-item{
    background-color: var(--light-s-gray);
}

html[data-theme=light-theme] .trading-item .trading-i-img{
    filter: invert(1);
}

html[data-theme=light-theme] .trading-item .trading-i-heading{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .trading-item .trading-i-text{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .trading-item .trading-icon{
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .trading-item:hover{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .trading-item:hover .trading-icon{
    color: var(--electricblue2);
}
/* --- Trade modal item --- */


/* --- --- */
html[data-theme=light-theme] .referral-links-bx{
    border: 1px solid var(--electricblue2-500);
}

html[data-theme=light-theme] .referral-links-bx:hover{
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .referral-links-bx .rlb-link{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .referral-links-bx .rlb-copy-btn{
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .referral-links-bx .rlb-copy-btn:hover{
    color: var(--electricblue2);
}
/* --- --- */


/* --- --- */
html[data-theme=light-theme] .ib-account-summary-item{
    background-color: var(--light-s-gray2);
    border: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .ib-commission-rate .icr-lable,
html[data-theme=light-theme] .ib-account-summary-item .iasi-lable{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .ib-commission-rate .icr-current,
html[data-theme=light-theme] .ib-account-summary-item .iasi-data{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .icr-indicator{
    font-weight: 600;
    color: var(--darkblue-700);
}
/* --- --- */


/* --- MT5 Account Details css start --- */
html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item{
    color: var(--darkblue-700);
    border: 1px solid var(--darkblue-300);
    font-weight: 600;
}

html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item.mt-real,
html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item.mt5-current{
    color: var(--electricblue2);
    border-color: var(--electricblue2);
    text-transform: capitalize;
}

html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-tags-bx .mt5-tag-item.mt-demo{
    color: var(--electricblue2);
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-account-id{
    font-weight: 600;
    color: var(--darkblue-700);
}

html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-account-balances{
    color: var(--black);
}

html[data-theme=light-theme] .mt5-account-bx .mt5-account-inner .mt5-account-balances span{
    color: var(--darkblue-700);
    font-weight: 600;
}
/* --- MT5 Account Details css end --- */


/* --- My Client info box css start --- */
html[data-theme=light-theme] .myclient-info-box{
    border-bottom: 1px solid var(--darkblue-100);
}

html[data-theme=light-theme] .myclient-info-box .myclient-tag .myclient-tag-item{
    color: var(--darkblue-700);
    border: 1px solid var(--darkblue-300);
    font-weight: 600;
}

html[data-theme=light-theme] .myclient-info-box .myclient-tag .myclient-tag-item.myclient-current{
    color: var(--electricblue2);
    border-color: var(--electricblue2);
}

html[data-theme=light-theme] .myclient-info-box .myclient-id{
    color: var(--black);
}
/* --- My Client Info Box css end --- */


/* --- Chart Flex Box Css Start --- */
html[data-theme=light-theme] .chart-flex-box .cfb-hr{
    background-color: var(--darkblue-100);
}

html[data-theme=light-theme] .cfb-data-item{
    background-color: var(--light-s-gray2);
    box-shadow: 0 0 0px 1px var(--darkblue-100);
}

html[data-theme=light-theme] .cfb-data-item:not(.active):hover{
    box-shadow: 0 0 0px 1px var(--electricblue2);
    background-color: var(--electricblue2-100);
}

html[data-theme=light-theme] .cfb-data-item.active{
    box-shadow: 0 0 0px 1px var(--electricblue2);
}

html[data-theme=light-theme] .cfb-data-item .cfd-di-label{
    font-weight: 600;
    color: var(--darkblue);
}

html[data-theme=light-theme] .cfb-data-item .cfd-di-count{
    font-weight: 600;
    color: var(--black);
}

html[data-theme=light-theme] .cfb-data-item .cfd-di-tags{
    font-weight: 600;
    color: var(--darkblue);
}
/* --- Chart Flex Box Css End --- */


/* --- Analytics summary page css start --- */
html[data-theme=light-theme] .summary-data-box{
    background-color: var(--white);
    border: 1px solid var(--darkblue-100);
    box-shadow: 0px 0px 15px -5px var(--black-100);
}

html[data-theme=light-theme] .summary-data-box.sdb-bg-black{
    background-color: var(--light-s-gray2);
    box-shadow: none;
}

html[data-theme=light-theme] .summary-data-box .sdb-item{
    color: var(--darkblue-700);
    font-weight: 600;
}

html[data-theme=light-theme] .summary-data-box .sdb-item div{
    color: var(--darkblue);
}

html[data-theme=light-theme] .summary-data-box .sdb-item.sdb-top-item div{
    color: var(--black);
}
/* --- Analytics summary page css end --- */


/* --- Relationship manager css start --- */
html[data-theme=light-theme] .relationship-manager-bx .rmb-data-bx .rmb-d-name{
    color: var(--darkblue);
}

html[data-theme=light-theme] .relationship-manager-bx .rmb-data-bx .rmb-d-name svg{
    background: #00000024;
}

html[data-theme=light-theme] .relationship-manager-bx .rmb-data-bx .rmb-d-name img{
    background: var(--darkblue-100);
}
/* --- Relationship manager css end --- */