#root,
body,
html{
    height: 100%;
}

html{
    scroll-behavior: initial !important;
}

*{
    text-decoration: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

.no-transition {
    transition: none !important;
}

a,a:hover{
    text-decoration: none;
    color: var(--white);
}

.common-word-wrap{
    word-break: break-word;
}

/* --- Website Fonts --- */
@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Regular.ttf");
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Medium.ttf");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
    font-display: swap;
}


/* --- Body tag Css --- */
body{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: var(--celeste);
    background-color: var(--darkblue);
    overflow: hidden;
}

.signinup-page{
    overflow: inherit;
}

.overflowhidden,
body.overflowhidden{
    overflow: hidden;
}

/* --- Common Color class --- */
.color-red,
.color-error{
    color: var(--error) !important;
}

.color-green,
.color-success{
    color: var(--success) !important;
}

.color-yellow,
.color-pending{
    color: var(--pending) !important;
}

.color-blue{
    color: var(--electricblue) !important;
}
/* --- Common Color class --- */


/* --- Common max width --- */
.maxwidth1920{
    max-width: 1920px;
    margin: auto;
}

/* --- Bootsrep Overaid Css Start --- */
.container-lg.cl-custome{
    max-width: 1140px;
}

.container-lg.cl-custome2{
    max-width: 1200px;
}
/* --- Bootsrep Overaid Css End --- */


/* --- Skeletant Css Start --- */
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: var(--dark);
    background-image: linear-gradient(45deg, var(--black-200) 30%, var(--celeste-100) 50%, var(--black-200) 70%);
    background-position: left;
    background-repeat: repeat;
    background-size: 1200px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 1s infinite linear;
    pointer-events: none;
    border-radius: 15px;
}

.sh-47{
    height: 47px;
}

.sh-95{
    height: 95px;
}

.sh-119{
    height: 119px;
}

.sh-137{
    height: 137px;
}

.sh-171{
    height: 171px;
}

.sh-215{
    height: 215px;
}

.sh-245{
    height: 245px;
}

.sh-635{
    height: 635px;
}

@-webkit-keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}
/* --- Skeletant Css End --- */


/* --- scroll bar css start --- */
.common-srollbar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.common-srollbar::-webkit-scrollbar-track {
    background-color: var(--darkblue);
}

.common-srollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--celeste-600);
}

.common-srollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--celeste-800);
}
/* --- scroll bar css end --- */

/* --- Close Btn Css Start --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--electricblue);
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.close-icon:hover{
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
}

@media screen and (min-width:700px) {
    .close-icon:hover{
        background-color: var(--electricblue-200);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active{
        background: var(--electricblue-200);
    }
}
/* --- Close Btn Css End --- */

/* --- Common Back Btn Start --- */
.CommonBackBtn{
    position: absolute;
    left: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: var(--electricblue);
    padding: 6px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.CommonBackBtn svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (min-width:700px) {
    .CommonBackBtn:hover{
        background-color: var(--electricblue-200);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .CommonBackBtn:active{
        background: var(--electricblue-200);
    }
}
/* --- Common Back Btn End --- */



/* --- Banner Img Bx Css Start --- */
.banner-img-bx{
    display: block;
}

.banner-img-bx img{
    width: 100%;
    border-radius: 10px;
}
/* --- Banner Img Bx Css End --- */


/* --- Common Button Css Start --- */
.common-btn-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    height: 36px;
    min-width: 100px;
    padding: 0 12px;
    font-size: 13px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--celeste);
    font-weight: 500;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.cbi-small{
    height: 32px;
    font-size: 12px;
    padding: 0 10px;
    min-width: auto;
}

.common-btn-item::after{
    content: "";
    position: absolute;
    inset: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--darkblue-600)));
    background-image: -webkit-linear-gradient(transparent, var(--darkblue-600));
    background-image: -moz-linear-gradient(transparent, var(--darkblue-600));
    background-image: -o-linear-gradient(transparent, var(--darkblue-600));
    background-image: linear-gradient(transparent, var(--darkblue-600));
    -webkit-border-radius: 54px;
       -moz-border-radius: 54px;
            border-radius: 54px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item span{
    white-space:nowrap;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item img{
    position: relative;
    top: 0.5px;
    width: 18px;
    height: 18px;
    -o-object-position: center;
       object-position: center;
    -o-object-fit: contain;
       object-fit: contain;
    margin-left: 3px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.common-btn-item.cbi-fill{
    border-color: var(--electricblue-500);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue-400)), to(var(--azure-400)) );
    background: -webkit-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: -moz-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: -o-linear-gradient( left, var(--electricblue-400), var(--azure-400) );
    background: linear-gradient( 90deg, var(--electricblue-400), var(--azure-400) );
}

.common-btn-item.cbi-whatsapp{
    border-color: #25D366;
    background: linear-gradient( 90deg, #25d46666,  #25d46699 );
}

.common-btn-item.cbi-outline{
    color: var(--electricblue);
    border-color: var(--electricblue-500);
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-outline::before{
    content: attr(cbidata);
    position: absolute;
    inset: 0px;
    color: transparent;
    background: var(--electricblue-500);
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transform: translateX(100%);
       -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
         -o-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-remove{
    color: var(--celeste-2-400);
    border-color: var(--celeste-400);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}



.common-btn-item.cbi-none{
    color: var(--electricblue);
}


.common-btn-item.cbi-disable{
    filter: grayscale(1);
}




/* --- common Button white --- */
.cbi-white.common-btn-item.cbi-fill{
    color: var(--white);
    border-color: var(--electricblue);
    background: -webkit-gradient( linear, left top, right top, from(var(--electricblue)), to(var(--azure)) );
    background: -webkit-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -moz-linear-gradient( left, var(--electricblue), var(--azure) );
    background: -o-linear-gradient( left, var(--electricblue), var(--azure) );
    background: linear-gradient( 90deg, var(--electricblue), var(--azure) );
}

.cbi-white.common-btn-item.cbi-outline{
    color: var(--darkblue);
    border-color: var(--darkblue);
}


.cbi-white.common-btn-item.cbi-outline::before{
    background-color: var(--darkblue);
}


@media only screen and (min-width: 768px) {

    .common-btn-item.cbi-fill:hover::after{
        opacity: 1;
        visibility: visible;
    }

    .common-btn-item.cbi-outline:hover span{
        /* transform: translateX(calc(100% + 20px)); */
        color: var(--celeste);
        z-index: 2;
    }
    
    .common-btn-item.cbi-outline:hover::before{
        -webkit-transform: translateX(0);
           -moz-transform: translateX(0);
            -ms-transform: translateX(0);
             -o-transform: translateX(0);
                transform: translateX(0);
    }

    .common-btn-item.cbi-none:hover img{
        -webkit-transform: translateX(5px);
           -moz-transform: translateX(5px);
            -ms-transform: translateX(5px);
             -o-transform: translateX(5px);
                transform: translateX(5px);
    }

    .cbi-white.common-btn-item.cbi-outline:hover{
        color: var(--white);
    }

    
    .common-btn-item.cbi-remove:hover{
        color: var(--error);
        border-color: var(--error);
    }
    
}

/* --- Common Button Css End --- */

/* --- Common Tabs Bx Start --- */
.common-tabs-bx{
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 1px;
    position: relative;
    white-space: nowrap;
}

.common-tabs-bx::-webkit-scrollbar{
    display: none;
}

.common-tabs-bx::after{
    background-color: var(--celeste-100);
    content: " ";
    height: 1px;
    left: 0;
    position: absolute;
    top: calc(100% - 1px);
    width: 100%;
}

.common-tabs-bx .ctb-item{
    color: var(--celeste-600);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 8px 15px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.common-tabs-bx .ctb-item::after{
    content: " ";
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    width: 100%;
    height: 2px;
    opacity: 0;
    z-index: 1;
    border-radius: 50px 50px 0 0;
    background-color:  var(--celeste-100);
    transition: opacity 0.3s ease-in-out;
}

.common-tabs-bx .ctb-item:hover,
.common-tabs-bx .ctb-item.active{
    color: var(--celeste);
}

.common-tabs-bx .ctb-item:hover::after{
    opacity: 1;
}

.common-tabs-bx .ctb-item.active::after{
    opacity: 1;
    background: linear-gradient(90deg, var(--electricblue), var(--azure));
}


/*  */
.common-tabs-bx.ctb-style-2{
    padding: 0;
    height: 35px;
}

.common-tabs-bx.ctb-style-2::after{
    display: none;
}

.common-tabs-bx.ctb-style-2 .ctb-item {
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 50px;
    overflow: hidden;
    z-index: 1;
    margin: 0 5px;
    border: 1px solid transparent;
}

.common-tabs-bx.ctb-style-2 .ctb-item.active {
    border-color: var(--electricblue-500);
}

.common-tabs-bx.ctb-style-2 .ctb-item::after {
    top: 0;
    height: 100%;
    border-radius: 0;
    z-index: -1;
}

.common-tabs-bx.ctb-style-2 .ctb-item.active::after {
    background: linear-gradient(90deg, var(--electricblue-400), var(--azure-400));
}


/*  */
@media screen and (max-width:576px) {
    .common-tabs-bx .ctb-item{
        font-size: 14px;
    }
}
/* --- Common Tabs Bx End --- */


/* --- Boot Modal Start --- */
.modal{
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog{
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
}

.custom-content .modal-content,
.modal-content.custom-content{
    background-color: var(--darkblue);
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
       -moz-box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
            box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px 1px inset;
}

.custom-content.cc-mw396px .modal-content{
    max-width: 396px;
    margin: auto;
}

.custom-modal-header{
    position: -webkit-sticky;
    position: sticky;
    padding: 20px 15px 20px;
}

.custom-modal-header .cmh-icon svg{
    color: var(--electricblue);
    width: 52px;
    height: 52px;
    margin: auto;
    display: block;
    margin-bottom: 5px;
}

.custom-modal-header .cmh-lable{
    font-size: 20px;
    font-weight: 500;
    color: var(--celeste);
    text-align: center;
}

.custom-modal-header .cmh-lable.small-cmh-lable{
    font-size: 16px;
}

.custom-modal-header .cmh-sub-lable{
    color: var(--celeste-600);
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
}

.custom-modal-header .cmh-sub-lable span{
    color: var(--celeste);
    text-decoration: underline;
}
/* --- Boot Modal End --- */


/* --- Common modal Information Modal Start --- */
.cm-info-item{
    background-color: var(--black-200);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    row-gap: 15px;
    margin: 0 15px 15px;
}

.cm-info-item .cmi-inner{
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    width: 100%;
}

.cm-info-item .cmi-inner.cmi-fd-column{
    flex-direction: column;
    justify-content: inherit;
    align-items: inherit;
    gap: 5px;
}

.cm-info-item .cmi-inner .cmi-i-info {
    color: var(--celeste-600);
    font-size: 14px;
    font-weight: 500;
}

.cm-info-item .cmi-inner .cmi-i-data {
    color: var(--celeste);
    font-size: 14px;
    font-weight: 500;
}

.cm-info-item .cmi-inner.cmi-copy .cmi-i-data {
    position: relative;
    padding-right: 35px;
    width: fit-content;
}

.cm-info-item .cmi-inner.cmi-copy .cmi-i-data .cmi-copy-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    color: var(--celeste);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    padding: 6px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    top: -5px;
}

.cm-info-item .cmi-inner.cmi-copy .cmi-i-data .cmi-copy-icon:hover {
    color: var(--electricblue);
    background-color: var(--celeste-100);
}
/* --- Common modal Information Modal End --- */


/* --- 
Lightgallery start 
--- */

.lg-counter{
    color: white !important;
}

.lg-toolbar .lg-icon{
    color: rgba(255, 255, 255, 0.7) !important;
}

.lg-toolbar .lg-icon:hover{
    color: #ffffff !important;
}

.lg-download.lg-icon{
    display: none !important;
}

.lg-outer .lg-thumb-outer,
.lg-backdrop{
    background-color: #0000004d !important;
    -webkit-backdrop-filter: blur(5px) !important;
            backdrop-filter: blur(5px) !important;
}

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover{
    border-color: var(--electricblue) !important;
}

.lg-prev,
.lg-next{
    width: 40px !important;
    height: 40px !important;
    color: var(--electricblue) !important;
    padding: 0 !important;
    padding-bottom: 2px !important;
    border: 2px solid var(--electricblue) !important;
    -webkit-border-radius: 50px !important;
       -moz-border-radius: 50px !important;
            border-radius: 50px !important;
    background-color: transparent !important;
    -webkit-transition: all 0.3s ease-in-out !important;
    -o-transition: all 0.3s ease-in-out !important;
    -moz-transition: all 0.3s ease-in-out !important;
    transition: all 0.3s ease-in-out !important;
}

.lg-prev:hover,
.lg-next:hover{
    color: white !important;
    background-color: var(--electricblue) !important;
}

.lg-prev:after {
    position: relative;
    top: -1px;
}

.lg-next:after {
    position: relative;
    top: -1px;
}

.lg-img-wrap{
    padding: 15px !important;
    padding-top: 0 !important;
}
/* --- 
Lightgallery end 
--- */


/* --- Breadcrumb Css Start --- */
.breadcrumb-main-bx{
    position: sticky;
    padding: 10px 20px;
    margin-bottom: 15px;
    top: 90px;
    z-index: 11;
    background: #0a1119;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 40%);
}

.breadcrumb-main-bx.bmb-static{
    position: static;
    top: inherit;
}

.breadcrumb-main-bx.bmb-back-btn{
    padding-left: 60px;
}

.breadcrumb-back-icon{
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--celeste-600);
    background-color: var(--celeste-100);
    transition: all 0.3s ease-in-out;
}

.breadcrumb-back-icon:hover{
    color: var(--celeste);
}

.breadcrumb-back-icon svg{
    position: relative;
    left: -2px;
}

.breadcrumb-heading-bx{
    font-size: 16px;
    color: var(--celeste);
    font-weight: 500;
    white-space: nowrap;
}

.breadcrumb-heading-bx svg{
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
    margin-right: 5px;
}


.breadcrumb-bx{   
    display: -webkit-inline-box;   
    display: -webkit-inline-flex;   
    display: -moz-inline-box;   
    display: -ms-inline-flexbox;   
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    width: 100%;
    margin-top: 8px;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 12px;
    font-weight: 500;
    margin-right: 5px;
    color: var(--celeste-700);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 500;
    color: var(--celeste-400);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover{
    color: var(--electricblue);
    cursor: pointer;
}
/* --- Breadcrumb Css End --- */



/* --- Pagination Btn Css Start --- */
.pagination-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.pagination-bx .pagination-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: var(--celeste-800);
    font-size: 14px;
    padding: 5px 10px;
    font-weight: 500;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

.pagination-bx .pagination-btn.disabled{
    color: var(--celeste-600);
    pointer-events: none;
}

.pagination-bx .pagination-btn:hover{
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.pagination-bx .pagination-text{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 16px;
    font-weight: 500;
    color: var(--celeste);
}

.pagination-bx .pagination-text div:nth-child(1){
    color: var(--electricblue);
}

.pagination-bx .pagination-text span{
    color: var(--celeste-600);
    padding: 0 3px;
}
/* --- Pagination Btn Css End --- */



/* --- Empty Box Css start --- */
.empty-div-bx {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    gap: 10px;
    min-height: 190px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    padding: 30px 10px;
}

.empty-div-bx .empty-img {
    width: 70px;
    display: block;
    -webkit-filter: invert(1);
            filter: invert(1);
}

.empty-div-bx.no-invert .empty-img {
    -webkit-filter: invert(0);
            filter: invert(0);
}

.empty-div-bx .empty-heading {
    line-height: normal;
    color: var(--celeste);
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .empty-sub-heading {
    font-weight: 400;
    color: var(--celeste-600);
    font-size: 14px;
    text-align: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.empty-div-bx .common-btn-item {
    margin-top: 5px;
}

@media screen and (max-width:576px) {
    .empty-div-bx .empty-heading {
        font-size: 16px;
    }
    .empty-div-bx .empty-sub-heading {
        font-size: 12px;
    }
}
/* --- Empty Box Css End--- */


/* --- Notice Start--- */
.legal-content{
    padding: 0 15px 25px;
}

.legal-content .legal-heading {
    font-size: 13px;
    color: var(--celeste);
    font-weight: 700;
    line-height: 1.5;
}

.legal-content ul li,
.legal-content p {
    font-size: 13px;
    line-height: 1.7;
    margin: 0;
    margin-top: 3px;
    color: var(--celeste-600);
    font-weight: 500;
    text-align: left;
}

.legal-content ul.font-16 li{
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.6;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin: 0;
    margin-left: 10px;
}


.legal-content ul.ul-style-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}

@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.legal-content ul li a,
.legal-content p a {
    font-weight: 700;
    text-decoration: none;
    color: rgb(0, 109, 209);
}

.legal-content ul a:hover,
.legal-content p a:hover {
    text-decoration: underline;
}

.legal-content ul strong,
.legal-content p strong {
    font-weight: 700;
    color: black;
}
/* --- Notice End --- */


/* --- Common Drop Bx Css Start --- */
.common-drop-item{
    position: relative;
    margin-bottom: 18px;
}

.common-drop-item.cdi-disabled{
    pointer-events: none;
    opacity: 0.4;
}

.common-drop-item .common-drop-bx{
    position: absolute;
    top: 100%;
    right: 0;
    visibility: hidden;
    width: 100%;
    z-index: 2;
}

.common-drop-item.cdi-view-bl .common-drop-bx{
    bottom: 100%;
    left: 0;
    right: inherit;
    top: inherit;
}

.common-drop-item .common-drop-bx .common-drop-inner{
    border-radius: 5px;
    max-height: 215px;
    background-clip: padding-box;
    background-color: var(--darkblue);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border: 1px solid var(--celeste-200);
    opacity: 0;
    visibility: hidden;
    transform: translateY(1px);
    box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
    overflow: hidden;
    overflow-y: auto;
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-track {
    background-color: var(--darkblue);
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--celeste-600);
}

.common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--celeste-800);
}

.common-drop-item.active .common-drop-bx{
    visibility: visible;
}

.common-drop-item.active .common-drop-bx .common-drop-inner{
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.common-drop-item.cdi-absolute{
    position: absolute;
    top: 5px;
    right: 5px;
    margin: 0;
    width: 230px;
}

.common-drop-item.cdi-absolute .common-drop-bx{
    top: 0;
}
/* --- Common Drop Bx Css End --- */

.selected-item {
    cursor: pointer;
    width: 100%;
    position: relative;
    /* z-index: 4; */
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 10px;
    border-radius: 4px;
    padding-right: 45px;
    background-color: var(--darkblue);
    border: 1px solid var(--celeste-300);
}

.selected-item.si-48h{
    height: 46px;
}

.si-38h .selected-item{
    height: 38px;
}

.common-drop-item:not(.error-group) .selected-item:hover,
.active > .selected-item{
    border-color: var(--electricblue2-800);
}


.selected-item.si-none {
    background-color: var(--black-200);
}



.selected-item .si-img{
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    object-position: center;
    object-fit: cover;
}

.selected-item .si-data-bx .si-d-heading{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-600);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}

.selected-item  .si-down-icon{
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--celeste-600);
    transform-origin: center;
    transition: all 0.3s ease-in-out;
}

.selected-item .si-down-icon svg{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.common-drop-item:not(.error-group) .selected-item:hover .si-down-icon{
    color: var(--electricblue);
}

.active > .selected-item  .si-down-icon{
    color: var(--electricblue);
    transform: translateY(-50%) rotate(180deg) ;
}



.selecte-option-label{
    font-size: 14px;
    padding: 5px 15px 0;
    margin-top: 10px;
    font-weight: 500;
    color: var(--celeste-400);
}

.selecte-option-item {
    cursor: pointer;
    width: 100%;
    position: relative;
    z-index: 4;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    font-size: 14px;
}

.selecte-option-item::after {
    content: " ";
    position: absolute;
    inset: 0;
    z-index: -1;
    border-color: var(--electricblue-500);
    background: linear-gradient(90deg, var(--electricblue-400), var(--azure-400));
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.selecte-option-item:not(.selected):hover {
    background-color: var(--black-200);
}

.selecte-option-item.selected:after {
    opacity: 1;
}

.selecte-option-item .soi-img{
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
    object-position: center;
    object-fit: cover;
}

.selecte-option-item .soi-data-bx .soi-d-heading{
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste-800);
}

.selecte-option-item.selected .soi-data-bx .soi-d-heading{
    color: var(--white);
}

.selecte-option-item .soi-data-bx .soi-d-heading .soi-dh-tag{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-left: 5px;
}

.selecte-option-item .soi-data-bx .soi-d-other{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.selecte-option-item .soi-data-bx .soi-d-balances{
    font-size: 14px;
    color: var(--white);
    font-weight: 500;
    white-space: nowrap;
    margin-top: 1px;
}

.selecte-option-item  .soi-down-icon{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--celeste-600);
    transform-origin: center;
    transition: all 0.3s ease-in-out;
}



/* Other */
.common-drop-item .common-drop-bx .signinup-group{
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: var(--darkblue);
}

.common-drop-item .common-drop-bx .signinup-group input{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--celeste-200);
}


.selected-item .si-lable{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
    margin-left: 5px;
}

.selected-item .si-data-bx .si-d-heading .si-dh-tag{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    margin-left: 5px;
    width: 70px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 11px;
    /* position: relative; */
    /* top: 5px; */

}

.selected-item .si-data-bx .si-d-other{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
}

.selected-item .si-data-bx .si-d-balances{
    font-size: 16px;
    color: var(--white);
    font-weight: 500;
    white-space: nowrap;
    margin-top: 1px;
}

/* Other */


/* --- cdi-currency Start --- */
.common-drop-item.cdi-currency{
    margin: 0;
}

.common-drop-item.cdi-currency .selected-item{
    margin: 0;
    background: none;
    border: none;
    width: 52px;
    height: 25px;
    padding: 5px 20px 5px 5px;
}

/* .selected-item .si-data-bx .si-d-heading{
    font-size: 12px;
} */

.common-drop-item.cdi-currency .selected-item .si-down-icon{
    padding: 3px;
    width: 20px;
    height: 20px;
}
.common-drop-item.cdi-currency .selected-item .si-down-icon svg{
    position: relative;
    top: 1px;
}

.common-drop-item.cdi-currency .common-drop-bx .common-drop-inner{
    width: 52px;
}
/* --- cdi-currency End --- */




/* --- Custome Table Css Start --- */
.custom-table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;
}

.custom-table thead{
    background-color: var(--darkblue);
}

.custom-table thead tr th{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 12px;
    white-space: nowrap;
    padding: 10px 15px;
    cursor: pointer;
}

.custom-table thead tr th .th-sort-icons{
    display: inline-flex;
    flex-direction: column;
    margin-left: 2px;
    position: relative;
    top: -2px;
    color: var(--celeste-600);
}

.custom-table thead tr th:hover .th-sort-icons,
.custom-table thead tr th.active .th-sort-icons{
    color: var(--celeste);
}

.custom-table thead tr th .th-sort-icons svg{
    width: 12px;
    height: 12px;
}

.custom-table thead tr th .th-sort-icons svg:nth-child(1){
    margin-bottom: -2px;
}

.custom-table thead tr th .th-sort-icons svg:nth-child(2){
    margin-top: -2px;
}

.custom-table thead tr th.asc .th-sort-icons svg:nth-child(1),
.custom-table thead tr th.desc .th-sort-icons svg:nth-child(2){
    color: var(--electricblue);
}

    
.custom-table tbody tr{
    position: relative;
}

    
.custom-table tbody tr .ct-tr-full-link{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
    
.custom-table tbody tr td{
    position: relative;
    padding: 12px 15px;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--celeste);
    white-space: nowrap;
    line-height: normal;
    background-color: var(--black-200);
}

.custom-table tbody tr td:last-of-type{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.custom-table tbody tr td:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

    
.custom-table tbody tr td div:not(.td-device-name, .td-status, .td-active-icon, .td-view, .symbol-bx),
.custom-table tbody tr td div.country-td{
    color: var(--celeste-600);
    margin-top: 5px;
    font-size: 12px;
}

.ai-i-data.symbol-bx,
.custom-table tbody tr td div.symbol-bx{
    display: flex;
    align-items: center;
    gap: 8px;
}

.ai-i-data.symbol-bx .symbol-icons,
.custom-table tbody tr td div.symbol-bx .symbol-icons{
    position: relative;
    width: 24px;
    height: 24px;
    top: -1px;
}

.ai-i-data.symbol-bx .symbol-icons img,
.custom-table tbody tr td div.symbol-bx .symbol-icons img{
    position: absolute;
    width: 75%;
    height: 75%;
}

.ai-i-data.symbol-bx .symbol-icons img:nth-child(1),
.custom-table tbody tr td div.symbol-bx .symbol-icons img:nth-child(1){
    top: 0;
    left: 0;
}

.ai-i-data.symbol-bx .symbol-icons img:nth-child(2),
.custom-table tbody tr td div.symbol-bx .symbol-icons img:nth-child(2){
    right: 0;
    bottom: 0;
}

.ai-i-data.symbol-bx .symbol-icons.si-single img:nth-child(1),
.custom-table tbody tr td div.symbol-bx .symbol-icons.si-single img:nth-child(1){
    width: 100%;
    height: 100%;
}


.custom-table tbody tr td div.country-td{
    display: flex;
    align-items: center;
    gap: 3px;
}

.custom-table tbody tr td div.country-td img{
    width: 18px;
    height: 12px;
    object-position: center;
    object-fit: contain;
}

.custom-table tbody tr td a{
    display: flex;
    width: 30px;
    height: 30px;
    padding: 7px;
    border-radius: 50%;
    background-color: var(--celeste-100);
    transition: all 0.3s ease-in-out;
}
    
.custom-table tbody tr td a:hover{
    background-color: var(--electricblue);
    color: var(--darkblue);
}
    
.custom-table tbody tr td a svg{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.custom-table tbody tr td .ct-detail-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.custom-table tbody tr td .ct-detail-btn svg{
    position: relative;
    top: 1px;
    width: 16px;
    height: 16px;
    object-position: center;
    object-fit: contain;
    margin-left: 8px;
}

.custom-table tbody tr td.td-wrap{
    text-wrap: wrap;
}

.custom-table tbody tr td .td-device-name{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 0;
    overflow: hidden;
    width: 120px;
}

.custom-table tbody tr td .td-view,
.custom-table tbody tr td .td-status{
    align-items: center;
    display: flex;
    gap: 5px;
}

.custom-table tbody tr td .td-view:hover{
    cursor: pointer;
    color: var(--electricblue);
}

.custom-table tbody tr td .td-active-icon{
    background-color: var(--success);
    border-radius: 50%;
    box-shadow: 0 0 10px var(--success);
    height: 12px;
    width: 12px;
}

.custom-table tbody tr td .td-status.td-remove{
    cursor: pointer;
    color: var(--celeste-800);
    transition: all 0.3s ease-in-out;
}

.custom-table tbody tr td .td-status.td-remove:hover{
    color: var(--error);
}

.custom-table tbody tr td .td-status.td-pending{
    color: var(--pending);
}

.custom-table tbody tr td.td-red,
.custom-table tbody tr td .td-status.td-rejected{
    color: var(--error);
}

.custom-table tbody tr td.td-green,
.custom-table tbody tr td .td-status.td-approved{
    color: var(--success);
}

.custom-table tbody tr td.td-blue{
    color: var(--electricblue);
}

.custom-table tbody tr td .td-view svg,
.custom-table tbody tr td .td-status.td-remove svg,
.custom-table tbody tr td .td-status.td-approved svg,
.custom-table tbody tr td .td-status.td-pending svg,
.custom-table tbody tr td .td-status.td-rejected svg{
    height: 20px;
    width: 20px;
    flex: 0 0 20px;
}


.custom-table.ct-hover tbody tr td{
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.custom-table.ct-hover tbody tr:hover td{
    background-color: var(--celeste-100);
}

.custom-table.ct-last-td-sticky tbody tr td:last-of-type{
    position: sticky;
    right: 0;
    background-color: #0a121a;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr:hover td:last-of-type{
    background-color: #212c37;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius{
    position: absolute;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: var(--darkblue);
    margin: 0;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0a121a;
    transition: background-color 0.3s ease-in-out;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr:hover td .td-last-radius::after{
    background-color: #212c37;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius.tlr-top{
    top: 0;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius.tlr-top::after{
    border-radius: 0 10px 0 0;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius.tlr-bottom{
    bottom: 0;
}

.custom-table.ct-last-td-sticky.ct-hover tbody tr td .td-last-radius.tlr-bottom::after{
    border-radius: 0 0 10px 0;
}

.custom-table.ct-small{
    border-spacing: 0 4px;
}

.custom-table.ct-small thead tr th,
.custom-table.ct-small tbody tr td{
    font-size: 12px;
    padding: 8px 10px;
}


/* --- Custome Table Css End --- */


/* --- Common Progress Bar Start --- */

.common-progress-bx{
    display: flex;
    align-items: center;
    gap: 15px;
}

.common-progress-bx .common-progress-bar{
    position: relative;
    width: 100%;
    flex: 0 1 100%;
    height: 4px;
    border-radius: 50px;
    background-color: var(--black-200);
}

.common-progress-bx .common-progress-bar .common-progress-inner{
    position: absolute;
    left: 0;
    top: 50%;
    height: 6px;
    transform: translateY(-50%);
    border-radius: 50px;
    background: var(--electricblue);
    transition: all 1s linear;
    background-image: linear-gradient(90deg, var(--electricblue), var(--azure) );
}
/* --- Common Progress Bar End --- */




/* -- Common accordion Css Start --- */
.common-accordion{
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    margin-top: 20px;
    padding: 10px 5px;
}

.common-accordion .acc-item{
    padding: 0 0;
    border-bottom: 1px solid var(--celeste-200);
}

.common-accordion:not(.vs-accordion) .acc-item:last-child {
    border-bottom: none;
}

.common-accordion .acc-item .acc-heding{
    position: relative;
    font-size: 16px;
    padding: 15px 0px;
    padding-right: 40px;
    cursor: pointer;
    font-weight: 500;
    color: var(--celeste);
}

.common-accordion .acc-item .acc-heding.collapsed {
    opacity: 0.9;
}

.common-accordion .acc-item .acc-heding.collapsed:hover {
    opacity: 1;
}

.common-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(180deg);
       -moz-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
         -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    background-image: url("../img/icons/Minus.svg");
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: 80%;
       -moz-background-size: 80%;
         -o-background-size: 80%;
            background-size: 80%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 26px;
    height: 26px;
    font-size: 20px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}



.common-accordion .acc-item .acc-heding.collapsed span.down-icon {
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url("../img/icons/Plus.svg");
}

.common-accordion .acc-item .acc-heding span.down-icon.di-two {
    background-image: url("../img/icons/Minus2.svg");
}

.common-accordion .acc-item .acc-heding.collapsed span.down-icon.di-two {
    background-image: url("../img/icons/Plus2.svg");
}


.common-accordion .acc-item .acc-heding span.down-icon svg{
    display: none;
}

.common-accordion .acc-item .acc-heding span.down-icon.di-three svg{
    width: 100%;
    height: 100%;
    transform: scale(0.9);
    display: block;
}

.common-accordion .acc-item .acc-heding.collapsed span.down-icon.di-three,
.common-accordion .acc-item .acc-heding span.down-icon.di-three{
    background-image: none;
}

.common-accordion .acc-item .acc-heding span.down-icon.di-three{
    color: var(--electricblue);
}

.common-accordion .acc-item .acc-heding.collapsed span.down-icon.di-three{
    color: var(--celeste);
}



.common-accordion .acc-item .faqs-data{
    color: var(--celeste-700);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
}

.common-accordion .acc-item .faqs-data strong{
    color: var(--celeste);
}

.common-accordion .acc-item .faqs-data a{
    color: var(--celeste);
    text-decoration: underline;
}

.common-accordion  .acc-item .faqs-data a:hover{
    color: var(--electricblue);
}

@media screen and (max-width:576px) {
    .common-accordion .acc-item .acc-heding{
        font-size: 14px;
    }

    .common-accordion .acc-item .faqs-data{
        font-size: 12px;
    }
}
/* -- Common accordion Css End --- */


/* --- Google_translate Css Start --- */
.GT-main-bx .GT-lable{
    color: var(--celeste);
    font-size: 14px;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 3px;
}

.GT-main-bx .close-icon{
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
}

.google_translate_element{
    font-weight: 400;
    font-family: 'Montserrat';
    color: var(--white);
    position: relative;
}

.google_translate_element select{
    font-weight: 400;
    display: block;
    color: var(--white);
    border: 0;
    font-size: 14px;
    background-color: var(--darkblue);
    border: 1px solid var(--celeste-400);
    width: 200px;
    outline: none;
    padding: 8px 30px 8px 10px;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: 'Montserrat';
    position: relative;
    z-index: 1;
    /* margin-bottom: -16px !important; */
}

.google_translate_element::after{
    content: " ";
    position: absolute;
    right: 9px;
    top: 11px;
    background-color: transparent;
    width: 10px;
    height: 10px;
    border: 2px solid var(--celeste-800);
    border-top: none;
    -webkit-transform: rotate(45deg);
       -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         -o-transform: rotate(45deg);
            transform: rotate(45deg);
    border-left: none;
    z-index: 1;
}

.google_translate_element:hover::after{
    border-color: var(--electricblue);
}

.google_translate_element select:hover{
    border-color: var(--electricblue);
}

#goog-gt-tt{
    display: none !important;
}
/* --- Google_translate Css End --- */


/* --- Chart Design Css Start--- */
.Mycharts{
    width: calc(100% + 34px);
}

.Mycharts.MC-style-2{
    width: calc(100% + 50px);
    transform: translate(-5px, 15px);
}

.Mycharts .MuiChartsLegend-series text{
    font-size: 12px !important;
}

.Mycharts .MuiChartsLegend-series .MuiChartsLegend-mark{
    width: 15px !important;
    height: 15px !important;
    transform: translate(5px, 3px);
}

.Mycharts .MuiChartsLegend-series text,
.Mycharts .MuiChartsAxis-label{
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    fill: var(--celeste) !important;
}

.Mycharts .MuiChartsAxis-line,
.Mycharts .MuiChartsAxis-tick{
    stroke: var(--celeste-200) !important;
}


.Mycharts .MuiChartsAxis-tickLabel{
    fill: var(--celeste-600) !important;
    font-family: 'Montserrat' !important;
    font-weight: 400 !important;
}

.Mycharts .MuiChartsGrid-line{
    stroke: #d2e5f108 !important;
}
/*--- Chart Design Css End ---*/


/*--- Platfrom Design Css Start ---*/
.platfrom-box{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 30px;
    border-radius: 10px;
    background: linear-gradient(180deg, #00d5ff66, #1f3f54 66.67%, #00d5ff66), #0a121a;
    background: linear-gradient(180deg, var(--electricblue-400), #1f3f54 66.67%, var(--electricblue-400)), #0a121a;
    overflow: hidden;
}

.platfrom-box::before{
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: #0d172166;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
}

.platfrom-box > *{
    position: relative;
    z-index: 1;
}

.platfrom-box .pb-tag{
    font-weight: 500;
    color: var(--celeste-600);
    font-size: 14px;
}

.platfrom-box .pb-heading{
    color: var(--celeste);
    font-size: 24px;
    font-weight: 600;
}

.platfrom-box .pb-heading span{
    color: var(--electricblue);
}

.platfrom-box .pb-img-box{
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    height: 100%;
}
    
.platfrom-box .pb-img-box img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: contain;
}

.platfrom-box .pb-img-box img:nth-child(2){
    display: none;
}

.platfrom-box.pb-right{
    align-items: flex-end;
}

.platfrom-box.pb-right .pb-heading,
.platfrom-box.pb-right .pb-tag{
    text-align: right;
}

.platfrom-box.pb-right .pb-img-box{
    right: inherit;
    left: -1.5%;
}


@media screen and (max-width:767px) {

    .platfrom-box .pb-img-box {
        align-self: center;
        position: relative;
        width: 100%;
        height: auto;
        right: inherit;
        top: inherit;
    }

    .platfrom-box .pb-img-box img{
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    .platfrom-box .pb-img-box img:nth-child(1){
        display: none;
    }

    .platfrom-box .pb-img-box img:nth-child(2){
        display: block;
    }

    .platfrom-box.pb-right{
        padding-top: 0;
    }

    .platfrom-box.pb-right .pb-img-box{
        left: inherit;
    }
}


@media screen and (max-width:576px) {
    .platfrom-box.pb-right{
        padding-top: 0;
        padding-left: 15px;
        padding-right: 15px;
    }

    .platfrom-box .pb-heading{
        margin-top: -10px;
    }
}

/* --- Playstores Buttons Start --- */
.playstores-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
}

.playstores-btn .playstores-item{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    padding: 15px;
    -webkit-border-radius: 12px;
       -moz-border-radius: 12px;
            border-radius: 12px;
    background-color: var(--darkblue-300);
    width: 178px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.playstores-btn .playstores-item:hover{
    background-color: var(--cyan-100);
}

.playstores-btn .playstores-item img{
    width: 40px;
    height: 40px;
}

.playstores-btn .playstores-item .playstores-data .psd-lable{
    font-size: 12px;
    color: var(--celeste-500);
    font-weight: 500;
}

.playstores-btn .playstores-item .playstores-data .psd-heading{
    font-size: 16px;
    color: var(--celeste);
    font-weight: 500;
}

@media screen and (max-width:420px) {
    .playstores-btn{
        flex-direction: column;
        align-self: center;
    }
}
/* --- Playstores Buttons End --- */

/*--- Platfrom Design Css End ---*/


/* --- Extra loader css start --- */
.loader {
  position: fixed;
  top: 150px;
  left: 300px;
  width: 1200px;
  height: 100%;
  background-color: var(--dark); /* Background color */
  background-image: linear-gradient(45deg, var(--black-200) 30%, var(--celeste-100) 50%, var(--black-200) 70%);
  background-position: left;
  background-repeat: repeat;
  background-size: 1200px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  animation: shine-lines 1s infinite linear; /* Shimmering effect */
  pointer-events: none; /* Prevent interactions */
  border-radius: 15px; /* Rounded corners */
}

@-webkit-keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 1100px;
  }
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 1100px;
  }
}
/* --- Extra loader css end --- */


.technical-analysis-bx{
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: calc(100% - 72px - 90px);
    max-width: 1920px;
    width: 100%;
    transition: padding-left 0.3s ease-in-out;
}

.technical-analysis-bx iframe{
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 992px) {
    .technical-analysis-bx{
        padding-left: 260px;
    }

    .account-main-section.active .technical-analysis-bx{
        padding-left: 65px;
    }
}