:root {
    --tradingviewHeaderHeight: 80px;
    --tradingviewHeadermobileHeight: 60px;

    --trading-blue: #0376bc;
    --trading-blue-dark: #006db1;
    --trading-red: #d84027;
    --trading-red-dark: #be2c15;
    --trading-green: #22b049;
    --trading-green-dark: #119735;
}

/* --- */
.common-scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.status-red {
    background: var(--trading-red);
    color: var(--white);
}

.status-green {
    background: var(--trading-green);
    color: var(--white);
}

.status-red-label {
    color: var(--trading-red);
    font-weight: 600;
}

.status-green-label {
    color: var(--trading-green);
    font-weight: 600;
}

.link-separator {
    margin: 10px 0;
    width: 100%;
    height: 1px;
    background: var(--celeste-600);
}

/* --- */

.icons svg,
.icons img {
    width: 16px;
    height: 16px;
    /* -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px; */
}

.badge {
    background-color: #535457;
    color: var(--white);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    padding: 4px 1px;
    font-size: 10px;
    position: absolute;
    top: 10px;
    right: auto;
    width: 18px;
    height: 18px;
    margin-left: 6px;
    text-align: center;
}

.desktop {
    display: block;
}

.mobile {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

}

/*--common heading--*/
.common-heading {
    font-size: 32px;
    font-weight: 700;
    color: var(--white);
}

.common-sub-heading {
    font-size: 16px;
    font-weight: 500;
    color: var(--white);
}


@media only screen and (max-width: 767px) {
    .common-heading {
        font-size: 20px
    }

    .common-sub-heading {
        font-size: 14px;
    }
}


/*--common popup css--*/
.custome-popup .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;

}

.custome-popup .modal-content {
    background-color: white;
    padding: 20px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    transform: translateY(100%);
    animation: slide-up 0.3s forwards;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}

.custome-popup .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/* --- Trading view header css start --- */
.tradingview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--tradingviewHeaderHeight);
    background-color: var(--darkblue);
    padding: 0 15px;
    position: relative;
    z-index: 101;
    overflow: initial;
    width: 100%;
}

.tradingview-header .tvh-logo img {
    display: block;
    height: 57px;
    margin: auto;
    width: 140px;
}

.tvh-left-side {
    display: flex;
    gap: 20px;
    align-items: center;
}

.tvh-logo-section {
    /* width: 315px; */
    display: flex;
}

.tvh-right {
    display: flex;
    align-items: center;
    gap: 10px;
}


@media only screen and (max-width: 1023px) {
    .tradingview-header .tvh-logo img {
        height: 45px;
        width: 45px;
    }
}

/* --- Trading view header css end --- */


/* --- Trading view body css start --- */

/* --- */
.tradingview-body {
    display: flex;
    height: calc(100% - var(--tradingviewHeaderHeight));
}

.tradingview-body .tvb-left {
    /* flex-basis: 280px; */
    flex-shrink: 0;
    background-color: var(--black-200);
    border-top: 1px solid #2a2e39;
    padding: 0;
    /* display: none; */
}

.tradingview-body .tvb-right {
    flex-grow: 1;
}

/* --- */


/* --- */
.tradingview-body .tvb-right .tvg-right-flex {
    display: flex;
    height: 100%;
}

.tradingview-body .tvb-right .tvg-right-flex .tvg-r-left {
    flex-grow: 1;
}

.tradingview-body .tvb-right .tvg-right-flex .tvg-r-right {
    flex-basis: 240px;
    flex-shrink: 0;
    background-color: var(--black-200);
    border-top: 1px solid #2a2e39;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
}

.tradingview-body .tvg-r-right.desktop {
    display: block;
}

.tradingview-body .tvg-r-right.mobile {
    display: none;
}

/*---market close notifacton css   --*/

.marketcloseinfo:after {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: var(--black); */
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    cursor: no-drop;
    z-index: 9;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0.4695) 80%);
}

.marketcloseinfo .mtc-info {
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    color: var(--white);
    text-align: center;
    padding: 0 5px;
}

.marketcloseinfo .mtc-info .mci-status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    padding-bottom: 5px;
}

.marketcloseinfo .mtc-info .mci-status svg {
    color: var(--trading-red);
    width: 25px;
    height: 25px;
}

.marketcloseinfo .mtc-info .mci-detail {
    font-size: 12px;
}


/* --- */


/* --- Trading view body css end --- */


/* --- Trading view widget container --- */

/* current-panel-name start */
.current-panel-name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: var(--celeste);
    font-weight: 600;
}

.current-panel-name img {
    width: 20px;
    height: 20px;
}

/* current-panel-name end */


/* buy-sell-btns start */
.buy-sell-btns {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.buy-sell-btns .bsb-item {
    cursor: pointer;
    width: 100%;
    border: 1px solid;
    padding: 10px 15px 20px 15px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
}

.buy-sell-btns .bsb-item.sell-btn {
    border-color: var(--trading-red);
}

.buy-sell-btns .bsb-item.buy-btn {
    border-color: var(--trading-blue);
}

.buy-sell-btns .bsb-item.sell-btn.bsb-selected {
    background-color: var(--trading-red);
}

.buy-sell-btns .bsb-item.buy-btn.bsb-selected {
    background-color: var(--trading-blue);
}

.buy-sell-btns .bsb-item.sell-btn.bsb-selected:hover {
    background-color: var(--trading-red-dark);
}

.buy-sell-btns .bsb-item.buy-btn.bsb-selected:hover {
    background-color: var(--trading-blue-dark);
}

.buy-sell-btns .bsb-item .bsb-label {
    color: var(--celeste-700);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 1px;
}

.buy-sell-btns .bsb-item .bsb-pricepips {
    display: flex;
    align-items: flex-end;
    color: var(--celeste);
    font-size: 12px;
    font-weight: 400;
}

.buy-sell-btns .bsb-item .bsb-pricepips span:nth-child(2) {
    font-size: 20px;
    font-weight: 500;
    display: block;
    line-height: normal;
}

.buy-sell-btns .bsb-item .bsb-pricepips span:nth-child(3) {
    align-self: flex-start;
    margin-left: 2px;
}

.buy-sell-btns .pips-spread-bx {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background-color: #0a121a;
    font-size: 10px;
    color: var(--celeste);
    padding: 3px 8px 0 8px;
    overflow: hidden;
    -webkit-border-radius: 2px 2px 0 0;
    -moz-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
}

.buy-sell-btns .pips-spread-bx::before,
.buy-sell-btns .pips-spread-bx::after {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: calc(50% - 5%);
    border-style: solid;
}

.buy-sell-btns .pips-spread-bx::before {
    left: 0;
    -webkit-border-radius: 2px 0 0 0;
    -moz-border-radius: 2px 0 0 0;
    border-radius: 2px 0 0 0;
    border-width: 1px 0 0 1px;
    border-color: var(--trading-red);
}

.buy-sell-btns .pips-spread-bx::after {
    right: 0;
    border-width: 1px 1px 0 0;
    border-color: var(--trading-blue);
    -webkit-border-radius: 0 2px 0 0;
    -moz-border-radius: 0 2px 0 0;
    border-radius: 0 2px 0 0;
}

/* buy-sell-btns end */

/* buy-sell-percent start */
.buy-sell-percent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px;
    gap: 5px;
}

.buy-sell-percent .bs-percent {
    font-weight: 600;
    font-size: 12px;
}

.buy-sell-percent .bs-percent.sell-percent {
    color: var(--trading-red);
}

.buy-sell-percent .bs-percent.buy-percent {
    color: var(--trading-blue);
}

.buy-sell-percent .bs-percent-bar {
    width: 100%;
    height: 3px;
    background-color: var(--trading-blue);
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.buy-sell-percent .bs-percent-bar .bs-percent-inner {
    height: 100%;
    background-color: var(--trading-red);
    transition: all 0.3s ease-in-out;
}

/* buy-sell-percent end */


/* panel-counter-bx start */
.panel-counter-bx {
    display: flex;
    margin-top: 20px;
    gap: 2px;
}

.panel-counter-bx .pcb-input-bx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    background-color: var(--darkblue);
    height: 44px;
    padding: 5px 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.common-drop-item.active .panel-counter-bx .pcb-input-bx,
.panel-counter-bx:hover .pcb-input-bx {
    background-color: var(--celeste-100);
}

.panel-counter-bx .pcb-input-bx input {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    font-size: 14px;
    color: var(--white);
}

.panel-counter-bx .pcb-input-bx input::placeholder {
    font-size: 14px;
}

.panel-counter-bx .pcb-input-bx .pcb-drop-label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--celeste-700);
    font-weight: 500;
    gap: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.panel-counter-bx .pcb-input-bx .pcb-drop-label:hover {
    color: var(--electricblue);
}

.panel-counter-bx .pcb-input-bx .pcb-drop-label svg {
    width: 10px;
    height: 10px;
    flex-basis: 10px;
}

.panel-counter-bx .pcb-input-bx .pcb-label {
    font-size: 12px;
    font-weight: 600;
    color: var(--darkblue);
    padding: 1px 3px;
    background-color: var(--celeste-600);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.panel-counter-bx .pcb-btns {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.panel-counter-bx .pcb-btns .pcb-btn-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 21px;
    background-color: var(--darkblue);
    color: var(--celeste);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-drop-item.active .pcb-btns .pcb-btn-item,
.panel-counter-bx:hover .pcb-btns .pcb-btn-item {
    background-color: var(--celeste-100);
}

.panel-counter-bx .pcb-btns .pcb-btn-item.pcb-plus:hover {
    background-color: var(--trading-green);
}

.panel-counter-bx .pcb-btns .pcb-btn-item.pcb-minus:hover {
    background-color: var(--trading-red);
}

.panel-counter-bx .pcb-btns .pcb-btn-item svg {
    width: 18px;
    height: 18px;
}

.panel-drop-bx {
    margin-bottom: 0;
    margin-top: 15px;
}

.panel-drop-bx .common-drop-bx {
    padding-top: 5px;
}

.panel-drop-item {
    cursor: pointer;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.panel-drop-item.active,
.panel-drop-item:hover {
    color: var(--electricblue);
}

.panel-tags {
    display: flex;
}

.panel-tags .panel-tag-item {
    position: relative;
    font-size: 10px;
    color: var(--celeste-700);
    padding: 0px 8px;
    margin-top: 8px;
}

.panel-tags .panel-tag-item:first-child {
    padding-left: 0;
}

.panel-tags .panel-tag-item:last-child {
    padding-right: 0;
}

.panel-tags .panel-tag-item::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: calc(100% - 5px);
    width: 1px;
    background-color: var(--celeste-300);
}

.panel-tags .panel-tag-item:last-child::after {
    content: none;
}

.other-panel-item {
    margin-top: 20px;
}

.other-panel-item .panel-switche {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--celeste);
    font-weight: 600;
}

.other-panel-item .panel-switche .signinup-group-switche {
    transform: scale(0.8);
    transform-origin: left center;
}

/* panel-counter-bx end */


/* buy-sell-confirmation start */
.buy-sell-confirmation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.buy-sell-confirmation .bs-choose-btn,
.buy-sell-confirmation .bs-cancel-btn,
.buy-sell-confirmation .bs-confirmation-btn {
    padding: 10px;
    font-size: 12px;
    width: 100%;
    color: var(--celeste);
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.buy-sell-confirmation .bs-choose-btn {
    pointer-events: none;
    color: var(--celeste-700);
    background-color: var(--celeste-100);
}

.buy-sell-confirmation .bs-confirmation-btn.sell-confirmation {
    background-color: var(--trading-red);
}

.buy-sell-confirmation .bs-confirmation-btn.sell-confirmation:hover {
    background-color: var(--trading-red-dark);
}

.buy-sell-confirmation .bs-confirmation-btn.buy-confirmation {
    background-color: var(--trading-blue);
}

.buy-sell-confirmation .bs-confirmation-btn.buy-confirmation:hover {
    background-color: var(--trading-blue-dark);
}

.buy-sell-confirmation .bs-cancel-btn {
    color: var(--celeste-700);
    border: 1px solid var(--celeste-500);
}

.buy-sell-confirmation .bs-cancel-btn:hover {
    color: var(--celeste);
    background-color: var(--celeste-100);
}

/* buy-sell-confirmation end */

/* buy-sell-details start */
.buy-sell-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 20px;
}

.buy-sell-details .bs-detail-item {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: var(--celeste-700);
}

/* buy-sell-details end */

/* --- Trading view widget container --- */


/* ResizableSidebar.css */
.tradingview-body .tvb-left .sidebar-container {
    display: flex;
    /* height: 100vh; */
}

.tradingview-body .tvb-left .sidebar {
    position: relative;
    cursor: pointer;
}

.tradingview-body .tvb-left .sidebar-content {
    padding: 10px
}

.tradingview-body .tvb-left .resizer {
    width: 3px;
    background-color: #2a2e39;
    cursor: ew-resize;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.tradingview-body .tvb-left .main-content {
    flex-grow: 1;
    padding: 20px;

}

.tradingview-body .tvb-left .sidebar.closed:hover {
    background-color: var(--celeste-100);
}

/*---trading view left css start --*/

.tvb-header .tvb-left-side {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 2px;
    line-height: 16px;
    text-transform: uppercase;
    color: var(--celeste);
}

.tvb-left .tvb-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.tvb-left .tvb-btn {
    display: flex;
    cursor: pointer;
    gap: 8px;
}

.tvb-left .tvb-btn .icon-btn {
    width: 20px;
    height: 20px;
}

.tvb-left .tvb-btn .icon-btn svg {
    color: var(--celeste);
}

.tvb-left .tvb-btn .icon-btn svg:hover {
    color: var(--trading-blue);
}

/* SearchBar.css start */

.tvb-search .search-bar {
    display: flex;
    border: 1px solid rgb(13 23 33);
    padding: 7px 10px;
    width: 100%;
    color: var(--darkblue);
    background-color: var(--darkblue);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.tvb-search .search-bar:hover {
    background-color: var(--celeste-100);
}

.tvb-search .icon {
    margin-right: 10px;
    color: var(--celeste-700);
    width: 20px;
    height: 20px;
}

.tvb-search .search-input {
    border: none;
    outline: none;
    width: 100%;
    color: var(--celeste-700);
    background-color: transparent
}

.tvb-search .search-input::placeholder {
    color: var(--celeste-700);
}

/* SearchBar css End */

/* InputDropdown css Start */
.custom-input-dropdown .input-dropdown {
    position: relative;
    width: 100%;
}

.custom-input-dropdown .dropdown-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--celeste-500);
    padding: 8px 10px;
    background-color: transparent;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.custom-input-dropdown .dropdown-container:hover {
    border: 1px solid var(--white-800);
}

.custom-input-dropdown .dropdown-container input {
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    color: var(--celeste);
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0.3px;
}

.custom-input-dropdown .down-arrow {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    -webkit-transition: transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    -moz-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
}

.custom-input-dropdown .down-arrow.open {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
}

.custom-input-dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid var(--darkblue);
    border-top: none;
    background-color: var(--darkblue);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: block;
    height: 50vh;
    overflow-y: auto;
}

.custom-input-dropdown .dropdown-item {
    padding: 7px 10px;
    cursor: pointer;
    color: var(--celeste-700);
    font-size: 14px;
}

.custom-input-dropdown .dropdown-item:hover {
    background-color: var(--celeste-100);
}

/* InputDropdown css End */

/* watchlist table start*/
.watchlist-table .wt-wrapper {
    overflow-x: auto;
    width: 100%;
}

.watchlist-table .fixed-column-table {
    width: 100%;
    border-collapse: collapse;
}

.watchlist-table .fixed-column-table th,
.watchlist-table .fixed-column-table td {
    /* border: 1px solid var(--celeste-500); */
    padding: 8px;
    white-space: nowrap;
}

.watchlist-table .fixed-column-table tr:first-child {
    border-top: none;
    border-bottom: 1px solid var(--celeste-500);
}

.watchlist-table .fixed-column-table tr {
    border-top: 1px solid var(--celeste-500);
}

.watchlist-table .fixed-column-table td {
    /* border-top: 1px solid var(--celeste-500); */
    font-size: 13px;
    letter-spacing: -.2pt;
    line-height: 18px;
    text-align: left;
}


.watchlist-table .fixed-column-table th.sticky-col,
.watchlist-table .fixed-column-table td.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: var(--darkblue);
    z-index: 1;
}

.watchlist-table .fixed-column-table th.sticky-col.last-col,
.watchlist-table .fixed-column-table td.sticky-col.last-col {
    position: sticky;
    right: 0;
    background-color: var(--darkblue);
    z-index: 1;
}

.watchlist-table .fixed-column-table td.sticky-col {
    font-size: 13px;
    letter-spacing: .3px;
    line-height: 20px;
    display: flex;
    gap: 7px;
    padding: 8px 8px;
    align-items: center;

}

.watchlist-table .fixed-column-table th {
    font-size: 13px;
    color: var(--celeste-700);
    font-weight: 400;
    letter-spacing: 0.2px;
    text-align: left;
}

.watchlist-table .fixed-column-table th.sticky-col {
    z-index: 2;

}

/* .watchlist-table .fixed-column-table thead th {
    background-color: var(--black-200)
} */

.watchlist-table .signal {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    margin: 0 auto;
}

.watchlist-table .small-btn {
    font-size: 12px;
    padding: 1px 4px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.watchlist-table .signal svg {
    padding: 3px 3px;
    margin: 0 2px;
}

.watchlist-table .return svg {
    width: 17px;
    height: 17px;
    padding-right: 3px;
}

.watchlist-table .rateing,
.watchlist-table .rateing svg {
    width: 16px;
    height: 16 px;
    margin: 0 auto;
    vertical-align: bottom;
}

.watchlist-table .rateing.active,
.watchlist-table .rateing.active svg {
    color: var(--trading-blue);
}

.watchlist-table .rateing svg:hover {
    color: var(--trading-blue);
    cursor: pointer;
}


.watchlist-table .rateing.active {
    color: var(--trading-blue);
}

.watchlist-table .wt-wrapper {
    /* scrollbar-width: thin;
    scrollbar-color: #2a2e39 #0a121a; */
    height: calc(100vh - 236px);
}

.watchlist-table .wt-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

.watchlist-table .wt-wrapper::-webkit-scrollbar-track {
    background-color: var(--darkblue);
}

.watchlist-table .wt-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--celeste-600);
}

.watchlist-table .wt-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: var(--celeste-800);
}

/** tab dropdown**/

.tvg-tab-section .tab.add-tab {
    position: relative;
}

.tvg-tab-section .dropdown-menu {
    position: absolute;
    top: 69px;
    left: auto;
    background-color: var(--darkblue);
    box-shadow: 0 0 20px 0px rgb(0 0 0 / 40%);
    border: 1px solid var(--celeste-200);
    z-index: 1000;
    width: 500px;
    padding: 10px;
    border-radius: 5px;
    display: block;
    right: auto;
    color: var(--celeste);
    min-height: 465px;
}

.tvg-tab-section .watchlist-table .wt-wrapper {
    height: 100%;
}

.tvg-tab-section .tvb-search .search-bar {
    background-color: var(--celeste-100);
}

.custome-popup.buy-sell-popup.watchlist-popup.tab-w-popup .tvb-header.watchlist-header {
    justify-content: center;
    display: flex;
}

.custome-popup.buy-sell-popup.watchlist-popup.tab-w-popup .close-button {
    padding-right: 0;
}

.tvg-tab-section .overlay {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent dark overlay */
    z-index: 10;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
}

.tab-dropdown .watchlist-table {
    height: 210px;
    overflow-y: auto;
}

.dropdown-menu.csid-menu {
    right: 0;
    left: 0;
    width: auto;
    border: none;
    top: 45px;
    min-height: auto;
}
.dropdown-menu::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

.dropdown-menu::-webkit-scrollbar-track {
    background-color: var(--darkblue);
}

.dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--celeste-600);
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
    background-color: var(--celeste-800);
}

/* tab style one start*/

.tvg-tab-section.desktop .tab {
    /* width: 88px;
    height: 70px;
    position: relative; */
    flex: inherit;
    overflow: hidden;
    width: auto;
}

.tvg-tab-section .tabs {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tvg-tab-section .tab-list {
    display: flex;
    justify-content: flex-start;
    /* border-bottom: 1px solid #ddd; */
    width: 100%;
}

.tvg-tab-section .tab .icons,
.tvg-tab-section .tab .icons img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.tvg-tab-section .tab span:nth-child(2) svg {
    width: 40px;
    height: 40px;
    color: var(--trading-red);
    margin-left: 5px;
}

.tvg-tab-section .tab {
    padding: 15px 10px 10px 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 16px;
    transition: background-color 0.3s;
    background-color: var(--celeste-100);
    color: var(--celeste);
    font-weight: 400;
    /* border-top: 6px solid transparent; */
    /* margin: 0 1px; */
    position: relative;
    flex: 1 1;
    overflow: hidden;
    width: auto;
    box-shadow: inset -20px -5px 30px -7px rgba(0, 0, 0, .7);

}

.tvg-tab-section .tab:hover {
    background-color: var(--celeste-100);
    color: var(--white);
    box-shadow: inset -20px -5px 30px -7px rgba(0, 0, 0, .7);
    -webkit-box-shadow: inset -20px -5px 30px -7px rgba(0, 0, 0, .7);
    -moz-box-shadow: inset -20px -5px 30px -7px rgba(0, 0, 0, .7);
}

.tvg-tab-section .tab.active {
    /* border-top: 6px solid var(--trading-blue); */
    font-weight: 400;
    flex: initial;
    overflow: inherit;
    width: fit-content;
    overflow: visible;
}

.tvg-tab-section.desktop button.tab.active:before {
    position: absolute;
    content: "";
    background: var(--trading-blue);
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
    right: 0;
}

.tvg-tab-section .tab-content {
    padding: 20px;
    width: 100%;
    text-align: center;
}

.tvg-tab-section.mobile {
    display: none;
}

.tvg-tab-section.desktop {
    position: relative;
    top: 8px;
    width: 60vw;
}

.tvg-tab-section .tab-list .tab .tvg-contry {
    display: none;
}

.tvg-tab-section .tab-list .tab.active .tvg-contry {
    display: inline-block;
}

.tvg-tab-section .tab-list .tab .tvg-cross.icons {
    position: absolute;
    top: -1px;
    left: -7px;
    visibility: hidden;
}

.tvg-tab-section .tab-list .tab:hover .tvg-cross.icons {
    visibility: visible;
}

.tvg-tab-section .tab-list .tab .tvg-cross.icons svg {
    width: 12px;
    height: 12px;
}

.tvg-tab-section.desktop .tab .icons,
.tvg-tab-section.desktop .tab .icons img {
    width: 32px;
    height: 32px;
    margin-right: 0;
    border-radius: 0;
}

.tvg-tab-section.desktop .tab.active .icons,
.tvg-tab-section.desktop .tab.active .icons img {
    margin-right: 4px;
}

.tvg-tab-section.desktop button.tab.add-tab {
    width: 65px;
    overflow: visible;
}

.tvg-tab-section.desktop button.tab.add-tab .signal.icons svg {
    width: 30px;
    height: 30px;
}

.tvg-tab-section.desktop .tab-list {
    /* white-space: nowrap;
    overflow-x: auto; */
    max-width: calc(100% - 60px);
    width: 100%;
}

.tvg-tab-section.desktop .tab.add-tab .rateing.icons {
    width: 16px;
    height: 16px;
}


@media only screen and (max-width: 1023px) {
    .tvg-tab-section.mobile {
        display: block;
    }

    .tvg-tab-section.desktop {
        display: none;
    }

    .tvg-tab-section.mobile .tab-list {
        white-space: nowrap;
        overflow-x: auto;
    }

    .tvg-tab-section.mobile .tab .icons,
    .tvg-tab-section.mobile .tab .icons img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
    }

    .tvg-tab-section.mobile .tab.active .icons,
    .tvg-tab-section.mobile .tab.active .icons img {
        margin-right: 5px;
    }

    .tvg-tab-section.mobile .tab {
        padding: 8px 18px 8px 18px;
        font-size: 13px;
        margin: 0;
        flex: inherit;
        overflow: visible;
        border-top: 2px solid transparent;
    }

    /* .tvg-tab-section.mobile .tab {
        border-top: 4px solid transparent;
    } */

    .tvg-tab-section.mobile .tab.active {
        border-top: 2px solid var(--trading-blue);
    }

    .tvg-tab-section.mobile .tab:last-child {
        position: sticky;
        right: 0;
        background: var(--black-900);
    }

    .tvg-tab-section.mobile .tab .signal.icons:last-child svg {
        color: var(--trading-blue);
        width: 25px;
        height: 25px;
    }

    .tvg-tab-section.mobile .tab.active {
        width: 130px;
    }

    .tvg-tab-section .tab-list .tab .tvg-contry {
        display: inline-block;
    }

    .tvg-tab-section .tab-list .tab .tvg-cross.icons {
        left: 0;
    }
}

/* tab style one end*/

/* tab style two Start*/

.tvg-tab-section.desktop .tabs.tab-style-two .tab {
    height: auto;
}

.tvg-tab-section.desktop .tabs.tab-style-two .tab.active:before {
    display: none;
}

.tradingview-body .tvg-r-left .tvg-tab-section.desktop {
    width: 100%;
}

.tvg-tab-section .tabs.tab-style-two .tab {
    background: transparent;
    border-bottom: 2px solid transparent;
    padding: 8px 50px;
    font-size: 12px;
    font-weight: 700;
    border-top: none;
    position: relative;
    color: var(--celeste-700);
    width: auto;
    flex: inherit;
    box-shadow: none;
}

.tvg-tab-section .tabs.tab-style-two .tab.active {
    border-bottom: 2px solid var(--trading-blue);
    border-top: none;
    color: var(--white);
}

.tvg-tab-section .tabs.tab-style-two .tab:hover {
    color: var(--white);
}

.tvg-tab-section .tabs.tab-style-two .tvg-tab-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 0 10px;
}

.tvg-tab-section .tabs.tab-style-two .tvg-tab-inner .tab-icon {
    display: flex;
    gap: 8px;
}

.tvg-tab-section .tabs.tab-style-two .tvg-tab-inner .tab-icon svg:hover {
    color: var(--trading-blue);
}

.tvg-tab-section .tab-content {
    padding: 5px 5px;
    width: 100%;
    text-align: center;
    max-height: 160px;
    overflow-y: auto;
}

.tvg-tab-section span.status.status-green {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
    margin: 0px 4px 0 0;
}

.tvg-tab-section span.status.status-red {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
    margin: 0px 4px 0 0;
}

.tvg-tab-section .icons.delet svg {
    width: 20px;
    height: 20px;
}

.tvg-tab-section .icons.delet {
    cursor: pointer;
}

.tvg-tab-section .icons.delet:hover {
    color: var(--trading-red);
}

.watchlist-table.tvg-data-table .wt-wrapper {
    height: 100%;
}

.watchlist-table.tvg-data-table {
    display: grid;
}

.watchlist-table.tvg-data-table .add-link {
    cursor: pointer;
    text-decoration: underline;
    color: var(--celeste-700);
}

.tvg-tab-section .tab-content::-webkit-scrollbar {
    width: 6px;
    height: 4px;
}

.tvg-tab-section .tab-content::-webkit-scrollbar-track {
    background-color: var(--darkblue);
}

.tvg-tab-section .tab-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--celeste-600);
}

.tvg-tab-section .tab-content::-webkit-scrollbar-thumb:hover {
    background-color: var(--celeste-800);
}

.tabs.tab-style-two .watchlist-table .signal {
    margin: 0;
}


.buy-sell-detail .tvg-tab-section .tabs.tab-style-two .tab {
    padding: 15px 42px;
    font-size: 14px;
}

.custome-popup.buy-sell-popup.buy-sell-detail .modal-content {
    padding: 0;
}

.buy-sell-detail .tvg-tab-section .tabs.tab-style-two .tab .badge {
    top: 17px;
}

td.normal-col {
    font-size: 13px;
    letter-spacing: .3px;
    line-height: 20px;
    display: flex;
    gap: 7px;
    padding: 8px 8px;
    align-items: center;
}

/* --- */

@media only screen and (max-width: 1023px) {
    .tvg-tab-section .tab-content {
        height: 500px;
        overflow-y: auto;
        padding: 15px 15px;
    }
}

/**sidebar toggle start***/

.sidebar.closed .sidebar-content {
    display: none;
}

.sidebar.open .side-icon {
    display: none;
}

.sidebar.closed {
    display: flex;
    align-items: center;
}

.sidebar.closed .side-icon {
    display: block;
}

.sidebar.closed .icon-btn.side-icon.icons {
    position: relative;
    bottom: 50px;
    cursor: pointer;
    left: 4px;
}

.sidebar.closed .icon-btn.side-icon.icons:hover {
    color: var(--trading-blue);
}

.sidebar.closed .icon-btn.side-icon.icons svg {
    min-height: 88vh;
}

/**sidebar toggle start End***/

/* tab style two End*/


@media only screen and (max-width: 1023px) {
    .tradingview-body .tvb-right .tvg-right-flex {
        flex-direction: column;
    }

    .tradingview-body .tvg-r-right.mobile {
        display: block;
    }

    .tradingview-body .tvg-r-right.desktop {
        display: none;
    }
}

/*---trading view left css End --*/

/*--- buy sell popup start --*/

.buy-sell-popup .modal-header {
    background: var(--darkblue);
    padding: 0 15px;
}

.buy-sell-popup .panel-counter-bx .pcb-input-bx {
    width: 100%;
}

.buy-sell-popup .buy-sell-confirmation {
    flex-direction: row;
}

.buy-sell-popup .bsp-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.buy-sell-popup .bsp-left {
    color: var(--white);
    font-size: 14px;
    letter-spacing: .3px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.buy-sell-popup .twobox-img img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    overflow: hidden;
}

.buy-sell-popup .twobox-img {
    width: 23px;
    height: 23px;
}

.buy-sell-popup .onebox-img {
    width: 23px;
    height: 23px;
}

.buy-sell-popup .onebox-img img {
    width: 20px;
    height: 20px;
}

.buy-sell-popup .bsp-left .icons {
    margin-right: 12px;
}

.buy-sell-popup .bsp-right span {
    padding-left: 7px;
    color: var(--white);
    font-weight: 400;
    font-size: 14px;
}

.buy-sell-popup .bsp-right span.status-green-label {
    color: var(--trading-green);
    font-weight: 600;
}

.buy-sell-popup .bsp-right span.status-red-label {
    color: var(--trading-red);
}

.buy-sell-popup .buy-sell-btns .bsb-item {
    padding: 5px 15px 5px 15px;
}

.buy-sell-popup .buy-sell-btns .bsb-item .bsb-pricepips {
    justify-content: center;
}

.buy-sell-popup .buy-sell-btns .bsb-item .bsb-label {
    text-transform: uppercase;
}

.buy-sell-popup .buy-sell-btns {
    background: var(--darkblue);
}

.buy-sell-popup .bs-detail {
    padding: 15px 15px;
    overflow-y: auto;
}

.buy-sell-popup .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.buy-sell-popup .modal-content {
    background-color: var(--black-900);
    border-radius: 0;
    width: 100%;
    text-align: center;
    transform: translateY(100%);
    animation: slide-up 0.3s forwards;
    height: 100%;
}

.buy-sell-popup .close-button {
    background: none;
    border: none;
    font-size: 34px;
    cursor: pointer;
    color: var(--trading-blue);
    padding-right: 15px;
    padding-left: 0;
    position: inherit;
}

.tradingview-body .tvg-r-right.mobile .buy-sell-btns .bsb-item {
    padding: 5px 15px 5px 15px;
    text-align: center;
}

.tvg-r-right.mobile .buy-sell-btns .bsb-item .bsb-pricepips {
    justify-content: center;
}

.tradingview-body .tvb-right .tvg-r-right.mobile {
    flex-basis: inherit;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--darkblue);
    z-index: 9;
}

.tradingview-body .tvb-right .tvg-r-right.mobile .current-panel-name {
    margin-bottom: 8px;
}

/*--- buy sell popup end --*/

/*--header demo popup start--*/

.tvh-balance {
    padding: 5px 15px 7px 15px;
    cursor: pointer;
    border-radius: 2px;
}

.tvh-balance:hover {
    background-color: var(--celeste-100);
}

.tvh-balance .tvhb-popup {
    font-size: 20px;
    color: var(--white);
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 5px;
}

.tvhb-popup .tvhb-curancy {
    color: var(--celeste-600);
    line-height: 15px;
}

.tvh-balance span:nth-child(1) {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
}

.tvh-balance span:nth-child(2) {
    font-weight: 300;
    font-size: 14px;
    color: var(--celeste-700);
}

.tvh-panel.mobile .icons svg {
    background: var(--darkblue);
    width: 30px;
    height: 30px;
    padding: 4px;
}

.tvg-demo-account {
    padding: 20px 0;
}

.tda-value {
    display: flex;
    justify-content: space-between;
    padding: 7px 0;
    cursor: pointer;
}

.oneclick-trading .bs-choose-btn {
    cursor: pointer;
    color: var(--trading-blue);
    text-decoration: underline;
    font-weight: 600;
}

.trading-info .bs-choose-btn {
    margin: 0 25px 15px;
    text-align: center;
}

.trading-info .bs-choose-btn a {
    color: var(--celeste-600);
    text-decoration: underline;
    font-weight: 600;
    letter-spacing: 0.2px;
    font-size: 14px;
    text-underline-offset: 3px;
    transition: all 0.3s ease-in-out;
}

.trading-info .bs-choose-btn a:hover {
    color: var(--white);
    transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1023px) {

    .tvh-balance {
        padding: 5px 8px 5px 8px;
    }

    .tvh-balance .tvhb-popup {
        font-size: 14px;
    }

    .tvh-balance span:nth-child(1) {
        font-size: 12px;
    }

    .tvh-balance span:nth-child(2) {
        font-size: 12px;
    }
}

/*--header demo popup End--*/
/* 
.tradingview-widget-container {
    height: calc(100% - 200px) !important;
} */

.tradingview-widget-container-visible {
    height: calc(100vh - 300px) !important;
  }
  
  .tradingview-widget-container-hidden {
    height: calc(100vh - 125px) !important;
  }

/*---sidebar toggle menu --*/

.trading-sidebar .menu-icon-bx {
    cursor: pointer;
    width: 24px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 999;
}

.trading-sidebar .menu-icon-bx span {
    display: block;
    height: 2px;
    background: var(--white);
    border-radius: 2px;
    transition: 0.3s;
    width: 24px;
}

.trading-sidebar .menu-icon-bx.active span:nth-child(1) {
    transform: rotate(45deg);
    position: relative;
    top: 6px;
    width: 75%;
    margin: 0 auto;
}

.trading-sidebar .menu-icon-bx.active span:nth-child(2) {
    opacity: 0;
}

.trading-sidebar .menu-icon-bx.active span:nth-child(3) {
    transform: rotate(-45deg);
    position: relative;
    top: -7px;
    width: 75%;
    left: 0px;
    margin: 0 auto;
}

.trading-sidebar .account-menu-bx {
    position: fixed;
    top: 0;
    left: -250px;
    /* Start off-screen */
    width: 250px;
    height: 100%;
    background-color: var(--darkblue);
    color: white;
    padding: 20px;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    z-index: 9;
    margin-top: 0;
    padding: 50px 10px 10px 10px;
}

.trading-sidebar .account-menu-bx.active {
    transform: translateX(250px);
    padding: 50px 10px 10px 10px;
}

/* .trading-sidebar .amb-link.bottom-fix {
    position: absolute;
    bottom: 15px;
    width: 100%;    
} */

.trading-sidebar .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--black-300);
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 2;
}

.trading-sidebar .overlay.active {
    opacity: 1;
    pointer-events: auto;
}

.tds-heder {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    text-transform: uppercase;
    align-items: center;
}

.tds-heder .tds-terminal {
    display: none;
}

@media only screen and (max-width: 1023px) {
    .tvb-left .sidebar.closed {
        display: none;
    }

    .tradingview-body .tvb-left .sidebar {
        display: none;
    }

    .tradingview-header {
        height: var(--tradingviewHeadermobileHeight);
    }

    .tradingview-body {
        height: calc(100% - var(--tradingviewHeadermobileHeight));
    }

    .tradingview-widget-container {
        height: calc(100% - 165px) !important;
    }
}

/*---wath list popup start --*/

.tvb-header.watchlist-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.tvb-header.watchlist-header .tvb-left-side {
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 500;
}

/*-- custome popover start--*/

.custom-popover {
    background: var(--celeste);
    border-radius: 5px;
}

.custom-popover .common-drop-item.active .panel-counter-bx .pcb-input-bx,
.custom-popover .panel-counter-bx:hover .pcb-input-bx {
    background-color: var(--black-900);
}

.custom-popover .common-drop-item.active .pcb-btns .pcb-btn-item,
.custom-popover .panel-counter-bx:hover .pcb-btns .pcb-btn-item {
    background-color: var(--black-900);
}

.custom-popover .popover-arrow::before,
.custom-popover .popover-arrow::after {
    border-top-color: var(--celeste);
    border-bottom-color: var(--celeste);
}

.custom-popover .panel-drop-item {
    color: #fff;
    font-family: 'Montserrat';
    font-weight: 500;
}

.custom-popover .panel-drop-item.active,
.custom-popover .panel-drop-item:hover {
    color: var(--electricblue);
    font-family: 'Montserrat';
    font-weight: 500;
}

/*-- custome popover End--*/

/*--singlebox img css start--*/

.onebox-img img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
}

.watchlist-table .onebox-img img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    overflow: hidden;
}

.watchlist-table .onebox-img {
    width: 16px;
    height: 16px;
}

/*--singlebox img css end--*/

/*--twobox img css start--*/
.main-img-box {
    display: inline-flex;
    align-items: center;
}

.main-img-box .tvg-contry {
    margin-left: 8px;
}

.twobox-img {
    position: relative;
    width: 32px;
    height: 32px;
    display: inherit;

}

.twobox-img img {
    width: 22px;
    height: 22px;
    border-radius: 100%;
    object-fit: cover;
}

.twobox-img img:first-child {
    position: absolute;
    top: 0;
    left: 0;

}

.twobox-img img:last-child {
    position: absolute;
    bottom: 0;
    right: 0;
}

.watchlist-table .twobox-img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    overflow: hidden;
}

.watchlist-table span.twobox-img img {
    width: 11px;
    height: 11px;
}

/*--twobox img css end--*/

@media only screen and (max-width: 1023px) {
    .tvg-tab-section.mobile .twobox-img {
        position: relative;
        width: 23px;
        height: 23px;
        display: inherit;
    }

    .tvg-tab-section.mobile .twobox-img img {
        width: 16px;
        height: 16px;
    }

    .tvg-tab-section.mobile .onebox-img img {
        width: 23px;
        height: 23px;
    }
}


/***table trading popup Start***/

.addtrade-modal .modal-content {
    background-color: var(--black-900);
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.01) 0px 207px 58px, rgba(0, 0, 0, 0.08) 0px 132px 53px, rgba(0, 0, 0, 0.27) 0px 74px 45px, rgba(0, 0, 0, 0.45) 0px 33px 33px, rgba(0, 0, 0, 0.52) 0px 8px 18px, rgba(0, 0, 0, 0.53) 0px 0px 0px, rgba(255, 255, 255, 0.08) 0px 1px 1px inset, rgba(0, 0, 0, 0.25) 0px 0px 0px
}

.addtrade-modal .modal-header {
    padding: 10px 15px;
    border-bottom: none;
    gap: 15px;
    background: var(--darkblue);
}

.addtrade-modal .modal-footer {
    border-top: none;
}

.addtrade-modal .modal-header .btn-close {
    filter: invert(1);
    opacity: 9;
    padding: 0;
    margin-right: 3px;
    box-shadow: none;
}

.addtrade-modal .trd-title {
    display: flex;
    gap: 6px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}

.addtrade-modal .trd-value {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: var(--celeste-700);
}

.addtrade-modal .twobox-img {
    width: 22px;
    height: 22px;
    margin-right: 2px;
}

.addtrade-modal .twobox-img img {
    width: 15px;
    height: 15px;
}

.trade-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.panel-counter-section {
    display: flex;
    gap: 10px;
}

.panel-counter-section .panel-counter-bx .pcb-input-bx,
.panel-counter-section .pcb-btns .pcb-btn-item {
    background: var(--darkblue);
}

.panel-counter-section .panel-counter-bx:hover .pcb-input-bx {
    background-color: var(--celeste-100);
}


.addtrade-modal .tab-navigation {
    display: flex;
    border-bottom: 1px solid var(--celeste-500);
    margin-bottom: 10px;
}

.addtrade-modal .tab-item {
    padding: 10px 10px;
    cursor: pointer;
    margin-right: 10px;
    color: var(--celeste-700);
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.addtrade-modal .tab-item:hover {
    color: var(--white);
}

/* .addtrade-modal .tab-item.active {
    border-bottom: 1px solid var(--trading-blue);
    color: var(--white);
} */

.addtrade-modal .tab-item.active:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    bottom: -2px;
    left: 0;
    right: 0;
    background-color: var(--trading-blue);
    top: auto;
}

.addtrade-modal .tab-content {
    padding: 10px 0 0;
    border-top: none;
}

.addtrade-modal .tab-panel {
    display: block;
}

.addtrade-modal .panel-message {
    font-weight: 500;
    font-size: 12px;
    font-weight: 500;
    color: var(--celeste-700);
    margin-right: 8px;
    padding: 0 8px 0 0;
}

.addtrade-modal .trad-panel-message {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    padding-top: 5px;
}

.addtrade-modal .trad-pips {
    border-right: 1px solid var(--celeste-500);
}

.addtrade-modal .trad-currancy {
    border-right: 1px solid var(--celeste-500);
}

.addtrade-modal .buy-sell-confirmation.modify-order {
    cursor: pointer;
}

.addtrade-modal .modify-order .bs-choose-btn {
    background: var(--darkblue);
    font-size: 14px;
    font-weight: 500;
}

.addtrade-modal .modify-order.buy-confirmation .bs-choose-btn {
    background-color: var(--trading-blue);
    color: var(--white);
    transition: all 0.3s ease-in-out;
}

.addtrade-modal .modify-order.buy-confirmation:hover .bs-choose-btn {
    background-color: var(--trading-blue-dark);
}

.addtrade-modal .panel-counter-bx .pcb-input-bx .pcb-label {
    background: transparent;
    color: var(--celeste-700);
}

.addtrade-modal .label {
    font-size: 12px;
    padding-bottom: 5px;
    color: var(--celeste-700);
}

.addtrade-modal .panel-counter-bx {
    width: 100%;
}

.addtrade-modal .panel-counter-bx .pcb-input-bx {
    width: inherit;
}

@media only screen and (max-width: 600px) {
    .addtrade-modal .trd-title {
        font-size: 14px;
    }

    .addtrade-modal .trd-value {
        font-size: 11px;
    }

    .addtrade-modal .modal-content {
        font-size: 12px;
    }

    .addtrade-modal.modal.show .modal-dialog {
        transform: none;
        height: 100%;
        margin: 0;
    }

    .addtrade-modal .modal-content {
        height: 100%;
    }
}           

/***table trading popup css End***/

/***Loader css Start***/

.spiner-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen */
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--darkblue);
    z-index: 9999;
  }
  
  .spiner-loader .spinner {
    width: 60px;
    height: 60px;
  }
/*   
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

  .spiner-loader.deactive {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 1s ease-in-out;
}

  /***Loader css End***/

  /***trading-info modal***/
  .no-trading-account {
       text-align: center; 
       padding: 15px 10px;
  }

  .no-trading-account .modal-content {
    padding: 20px 5px 20px;
}

  .no-trading-account .common-box-heading {
     margin-bottom: 5px;
     font-size: 20px;   
  }

  .no-trading-account .bs-choose-btn {
    margin: 0 25px 0;
    cursor: pointer;
  }

