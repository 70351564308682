.login_register_header{
    padding: 15px 50px;
}

.login_register_header .lgh-inner{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.lgh-logo-bx img{
    width: 160px;
    -webkit-filter: brightness(10);
            filter: brightness(10);
}

.lgh-videolink{
    display: block;
    font-size: 14px;
    text-align: center;
    color: #708494;
    font-weight: 500;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    color: #608ed7;
    margin-top: 25px;
    margin-bottom: 5px;
}

.lgh-videolink:hover{
    color: #749cdc;
}


.footer-text{
    font-size: 12px;
    text-align: center;
    color: #708494;
    font-weight: 500;
}

.footer-text a{
    color: #608ed7;
}

.footer-text a:hover{
    color: #749cdc;
}

@media screen and (max-width:992px) {
    .login_register_header{
        padding: 15px 15px;
    }
}

@media screen and (max-width:576px) {
    .login_register_header{
        height: 100px;
        position: sticky;
        z-index: 1;
        top: 0;
    }
    
    .login_register_header.active{
        background: transparent;
        backdrop-filter: blur(10px);
    }
}

/* --- 
Login Background Img 
--- */
.lghBg-main-bx{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: -1;
    pointer-events: none;
    opacity: 0.4;
}

.lghBg-main-bx img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
}

@media screen and (max-width:576px) {
    .lghBg-main-bx{
        height: 339px;
        opacity: 0.8;
    }
}


.lgh-wraper{
    min-height: -webkit-calc(100vh - 96px - 96px);
    min-height: -moz-calc(100vh - 96px - 96px);
    min-height: calc(100vh - 96px - 96px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 20px 0;
}

.lgh-wraper .container-lg.cl-custome{
    padding: 0 10px;
}


@media screen and (max-width:576px) {
    .lgh-wraper{
        /* max-height: -webkit-calc(100vh - 110px);
        max-height: -moz-calc(100vh - 110px);
        max-height: calc(100vh - 110px); */
        min-height: initial;
        min-height: initial;
        min-height: initial;
        padding-top: 135px;
        /* overflow: hidden; */
        /* overflow-y: auto; */
    }

    .lgh-wraper::-webkit-scrollbar{
        display: none;
    }
}


/* ---
SignInUp Back Btn
--- */
.SignInUpBackBtn{
    position: fixed;
    z-index: 1;
    left: 20px;
    top: 36px;
    width: 28px;
    height: 28px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    padding: 0px;
    display: none;
}

@media screen and (max-width:576px) {
    .SignInUpBackBtn{
        display: block;
    }

    .BackBtnIsShow{
        display: none;
    }
}


.SignInUpThemeMode{
    position: fixed;
    z-index: 1;
    right: 20px;
    top: 29px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 8px;
    color: var(--celeste);
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}

.SignInUpThemeMode:hover{
    color: var(--electricblue);
    background-color: var(--celeste-100);
}

.signinup-width{
    width: 100%;
    margin: 15px auto;
    padding: 0 20px;
}


/* @media screen and (max-width:576px) {
    .signinup-width{
        max-width: 320px;
        width: 100%;
    }
} */

/* ---
Seconde Screen
--- */
.signinup-screen {
    max-width: 396px;
    width: 100%;
    position: relative;
    margin: auto;
    padding: 10px 0px 10px;
    background: #090a0bbf;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    /* overflow: hidden; */
    /* -webkit-transform: scale(0.88);
       -moz-transform: scale(0.88);
        -ms-transform: scale(0.88);
         -o-transform: scale(0.88);
            transform: scale(0.88);
    -webkit-transform-origin: top center;
       -moz-transform-origin: top center;
        -ms-transform-origin: top center;
         -o-transform-origin: top center;
            transform-origin: top center;
    margin-bottom: -4.6%; */
}

/* @media screen and (max-width:576px) {
    .signinup-screen {
        -webkit-transform: initial;
           -moz-transform: initial;
            -ms-transform: initial;
             -o-transform: initial;
                transform: initial;
        -webkit-transform-origin: initial;
           -moz-transform-origin: initial;
            -ms-transform-origin: initial;
             -o-transform-origin: initial;
                transform-origin: initial;
        margin-bottom: initial;
    }
} */

/* ---
Signinup Link
--- */
.signinup-link-outer-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    font-size: 12px;
}

.slob-fdc{
    gap: 1px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.slob-fdc.fdc-two{
    gap: 5px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.signinup-link{
    color: var(--white-800);
    display: block;
    font-size: 12px;
    font-weight: 500;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    cursor: pointer;
}

.signinup-link:hover{
    color: var(--white);
    text-decoration: underline;
}

.signinup-link-two{
    cursor: pointer;
    color: #0294B5;
    display: block;
    font-size: 12px;
    font-weight: 500;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
}

.signinup-link-two:hover{
    color: #0294B5;
    text-decoration: underline;
}

/* ---
Screen heading
--- */
.signinup-header{
    margin-top: 8px;
    margin-bottom: 18px;
    padding: 0 20px;
}

.screen-hed {
    color: var(--celeste);
    font-weight: 500;
    font-size: 16px;
    text-align: left;
}

.screen-pera {
    color: var(--celeste-600);
    font-weight: 500;
    font-size: 12px;
    text-align: left;
    margin-top: 5px;
}

.screen-pera .sp-otp-link{
    cursor: pointer;
    color: white;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    /* color: white;
    transition: all 0.3s ease-in-out;
    width: 86px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 12px;
    margin-left: 3px;
    position: relative;
    top: 2px; */
}

.screen-pera .sp-email {
    color: white;
}

.screen-pera .sp-otp-link:hover{
    color: #0294B5;
    text-decoration: underline;
}


/* signinup-footer */
.signinup-social-login-bx{
    margin-top: 18px;
    margin-bottom: 18px;
    padding: 0 20px;
}

.signinup-social-login-bx .sslb-or-div{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.signinup-social-login-bx .sslb-or-div .after-befor-line{
    background-color: #6C7080;
    width: 100%;
    height: 1px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
}

.signinup-social-login-bx .sslb-or-div span{
    white-space: nowrap;
    font-size: 12px;
    color: #6C7080;
    font-weight: 500;
    padding: 0 15px;
}

.signinup-social-login-bx .sslb-btn-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 16px;
    margin-top: 18px;
}

.signinup-social-login-bx .sslb-btn-bx .sslb-btn-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 44px;
    height: 44px;
    -webkit-border-radius: 50px;
       -moz-border-radius: 50px;
            border-radius: 50px;
    background-color: var(--white);
    cursor: pointer;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-social-login-bx .sslb-btn-bx .sslb-btn-item img{
    width: 26px;
    height: 26px;
}

.signinup-social-login-bx .sslb-btn-bx .sslb-text-popup{
    position: absolute;
    left: 50%;
    bottom: 100%;
    background-color: white;
    color: black;
    white-space: nowrap;
    -webkit-transform: translate(-50%, -15px);
       -moz-transform: translate(-50%, -15px);
        -ms-transform: translate(-50%, -15px);
         -o-transform: translate(-50%, -15px);
            transform: translate(-50%, -15px);
    padding: 3px 5px 3px;
    font-size: 14px;
    font-weight: 500;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.signinup-social-login-bx .sslb-btn-bx .sslb-text-popup::after{
    content: "";
    position: absolute;
    left: 50%;
    bottom: -5px;
    -webkit-transform: translateX(-50%) rotate(45deg);
       -moz-transform: translateX(-50%) rotate(45deg);
        -ms-transform: translateX(-50%) rotate(45deg);
         -o-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
    background-color: white;
    width: 10px;
    height: 10px;
}

.signinup-social-login-bx .sslb-btn-bx .sslb-btn-item:hover{
    border-color: #0294B5;
}

.signinup-social-login-bx .sslb-btn-bx .sslb-btn-item:hover .sslb-text-popup{
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate(-50%, -10px);
       -moz-transform: translate(-50%, -10px);
        -ms-transform: translate(-50%, -10px);
         -o-transform: translate(-50%, -10px);
            transform: translate(-50%, -10px);
}