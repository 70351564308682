:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-900: rgba(0, 0, 0, 0.9);
    --black-800: rgba(0, 0, 0, 0.8);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);

    --darkblue: #0d1721;
    --darkblue-900: rgba(13, 23, 33, 0.9);
    --darkblue-800: rgba(13, 23, 33, 0.8);
    --darkblue-700: rgba(13, 23, 33, 0.7);
    --darkblue-600: rgba(13, 23, 33, 0.6);
    --darkblue-500: rgba(13, 23, 33, 0.5);
    --darkblue-400: rgba(13, 23, 33, 0.4);
    --darkblue-300: rgba(13, 23, 33, 0.3);
    --darkblue-200: rgba(13, 23, 33, 0.2);
    --darkblue-100: rgba(13, 23, 33, 0.1);

    --cyan:#00ffff;
    --cyan-900: rgba(0, 255, 255, 0.9);
    --cyan-800: rgba(0, 255, 255, 0.8);
    --cyan-700: rgba(0, 255, 255, 0.7);
    --cyan-600: rgba(0, 255, 255, 0.6);
    --cyan-500: rgba(0, 255, 255, 0.5);
    --cyan-400: rgba(0, 255, 255, 0.4);
    --cyan-300: rgba(0, 255, 255, 0.3);
    --cyan-200: rgba(0, 255, 255, 0.2);
    --cyan-100: rgba(0, 255, 255, 0.1);

    --electricblue:#00d5ff;
    --electricblue-900: rgba(0, 213, 255, 0.9);
    --electricblue-800: rgba(0, 213, 255, 0.8);
    --electricblue-700: rgba(0, 213, 255, 0.7);
    --electricblue-600: rgba(0, 213, 255, 0.6);
    --electricblue-500: rgba(0, 213, 255, 0.5);
    --electricblue-400: rgba(0, 213, 255, 0.4);
    --electricblue-300: rgba(0, 213, 255, 0.3);
    --electricblue-200: rgba(0, 213, 255, 0.2);
    --electricblue-100: rgba(0, 213, 255, 0.1);
    

    --azure:#007bff;
    --azure-900: rgba(0, 123, 255, 0.9);
    --azure-800: rgba(0, 123, 255, 0.8);
    --azure-700: rgba(0, 123, 255, 0.7);
    --azure-600: rgba(0, 123, 255, 0.6);
    --azure-500: rgba(0, 123, 255, 0.5);
    --azure-400: rgba(0, 123, 255, 0.4);
    --azure-300: rgba(0, 123, 255, 0.3);
    --azure-200: rgba(0, 123, 255, 0.2);
    --azure-100: rgba(0, 123, 255, 0.1);

    --celeste: #d2e5f1;
    --celeste-900: rgba(210, 229, 241, 0.9);
    --celeste-800: rgba(210, 229, 241, 0.8);
    --celeste-700: rgba(210, 229, 241, 0.7);
    --celeste-600: rgba(210, 229, 241, 0.6);
    --celeste-500: rgba(210, 229, 241, 0.5);
    --celeste-400: rgba(210, 229, 241, 0.4);
    --celeste-300: rgba(210, 229, 241, 0.3);
    --celeste-200: rgba(210, 229, 241, 0.2);
    --celeste-100: rgba(210, 229, 241, 0.1);

    --electricblue2: #17a8c9;
    --electricblue2-900: rgba(23, 169, 201, 0.9);
    --electricblue2-800: rgba(23, 169, 201, 0.8);
    --electricblue2-700: rgba(23, 169, 201, 0.7);
    --electricblue2-600: rgba(23, 169, 201, 0.6);
    --electricblue2-500: rgba(23, 169, 201, 0.5);
    --electricblue2-400: rgba(23, 169, 201, 0.4);
    --electricblue2-300: rgba(23, 169, 201, 0.3);
    --electricblue2-200: rgba(23, 169, 201, 0.2);
    --electricblue2-100: rgba(23, 169, 201, 0.1);

    --light-s-gray: #f3f4f6;
    --light-s-gray2: #f4f6f9;
    --light-s-gray3: #fbfbfb;

    --error: #fe6b6b;
    --success: #00c28c;
    --pending: #FFC107;

    --darkblue-300:#0d17214d;
}