/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--darkblue);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
    -webkit-background-size: 200% 200%;
       -moz-background-size: 200% 200%;
         -o-background-size: 200% 200%;
            background-size: 200% 200%;
    background-position: 0% 50%;
	-webkit-animation: preloader_gradient 2s infinite forwards;
	   -moz-animation: preloader_gradient 2s infinite forwards;
	     -o-animation: preloader_gradient 2s infinite forwards;
	        animation: preloader_gradient 2s infinite forwards;
}

@-webkit-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-moz-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@-o-keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes preloader_gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.web-preloader span.web-inner {
    width: 120px;
    height: 120px;
    line-height: 120px;
    margin: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);
}

.web-preloader span.web-inner img {
    width: 120px;
    height: 120px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    position: relative;
    -webkit-animation: gogoloader-2 5ms infinite;
       -moz-animation: gogoloader-2 5ms infinite;
         -o-animation: gogoloader-2 5ms infinite;
            animation: gogoloader-2 5ms infinite;
}

@-webkit-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@-moz-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

@-o-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -o-transform: scale(0);
           transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
           transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
          -o-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
          -o-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(50px);
       -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
         -o-transform: translateY(50px);
            transform: translateY(50px);
    -webkit-transition: all 0.3s 0.5s ease-in-out;
    -o-transition: all 0.3s 0.5s ease-in-out;
    -moz-transition: all 0.3s 0.5s ease-in-out;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 1s ease-in-out;
    -o-transition: all 0.3s 1s ease-in-out;
    -moz-transition: all 0.3s 1s ease-in-out;
    transition: all 0.3s 1s ease-in-out;
}